import React, {Fragment} from 'react';
import Header from '../../components/header'
import Footer from '../../components/footer'
import './style.css';

import whiteSectionBackground from '../../images/bg/white-with-squares.png'
import policyGraphic from '../../images/bg/pexels-canva-studio-3153199.jpg';
import policyGraphic2 from '../../images/gallery/scott-graham-5fNmWej4tAA-unsplash.jpg';
import pageTitleBackground from '../../images/titles/PolicyPageTitle.png';
import CallToAction from '../../components/CallToAction';

const PolicyPage =() => {

    document.title = "Policy Management | NonprofitsHQ"
    
    return(
        <Fragment>
            <Header/>
                <main>

                <section className="breadcrumb-area d-flex align-items-center parallax" style={{background: `url(${pageTitleBackground}) no-repeat`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-12">
                                <div className="breadcrumb-wrap text-left">
                                    <div className="breadcrumb-title mb-30">
                                        <h2>Policy</h2>
                                        <p style={{fontSize: '22px', color: '#fff', maxWidth: '800px'}}>Easily manage, create and revise your organization's policies with NonprofitsHQ.</p>
                                        <div className="breadcrumb-wrap">

                                    <nav aria-label="breadcrumb">

                                    </nav>
                                </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <div className="counter-area pt-60 pb-40">
                    <div className="container">
                        <div className="row wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-lg-6">
                                <div className="section-title center-align mb-40 text-left">
                                    <h2 className="secondary-heading">
                                        Evolve with NonprofitsHQ
                                    </h2>
                                    <p>
                                        Engage a secure hub for organizations to house their current policies and bylaws allowing collaboration to shape new ones.
                                        This platform offers a centralized space where nonprofits can store, update, and discuss essential frameworks with ease.
                                        NonprofitsHQ helps partners collaborate, empowering organizations to uphold their values and adapt to evolving needs effortlessly.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="section-title center-align mb-6 text-left" style={{borderRadius: '5px', border: '10px solid #075272ff'}}>
                                    <img src={policyGraphic} alt=''/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className='blue-section d-flex justify-content-center pb-50'>
                    <div className="container mt-5">
                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-md-12 mb-4 text-center">
                                <h2>Streamline your policies with NonprofitsHQ:</h2>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                        <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Secure Storage</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Create Policies</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Modify Policies</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Share Policies</p>
                                    </div>
                                </div>
                            </div>                         

                        </div>
                    </div>
                </section>

                <section className='bootstrap-section pt-60 pb-40' style={{background: `url(${whiteSectionBackground}) no-repeat`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-md-5">
                                <div className="left-side">
                                    <div className="section-title center-align mb-6 text-left" style={{borderRadius: '5px', border: '10px solid #075272ff'}}>
                                        <img src={policyGraphic2} alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="right-side">
                                    <div className="section-title center-align mb-6 text-left" style={{background: 'rgba(255,255,255, .9)', padding: '5px', borderRadius: '15px'}}>
                                        <h2 className="secondary-heading">
                                            Nonprofits change, use the tool that adapts with you.
                                        </h2>
                                        <p>
                                        Nonprofits frequently adapt their policies to address evolving needs and challenges in their communities. 
                                        Utilizing a secure tool for storing, collaborating, and sharing work ensures that these policy changes are implemented efficiently.
                                        NonprofitsHQ foster better communication among team members and stakeholders, ultimately enhancing the nonprofit's impact and effectiveness.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <CallToAction />

            </main>
            <Footer/>
    </Fragment>
    )
};
export default PolicyPage;