import React, {Fragment} from 'react';
import Header from '../../components/header'
import Footer from '../../components/footer'

import pageTitleBackground from '../../images/titles/PolicyPageTitle.png';

const SlaSecurityPage =() => {

    document.title = "SLA & Security | NonprofitsHQ"
    
    return(
        <Fragment>
            <Header/>
                <main>

                <section className="breadcrumb-area d-flex align-items-center parallax" style={{background: `url(${pageTitleBackground}) no-repeat`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-10">
                                <div className="breadcrumb-wrap text-left">
                                    <div className="breadcrumb-title mb-30">
                                    <h2>SLA & Security Policy</h2>
                                        {/* <p style={{fontSize: '22px', color: '#fff', maxWidth: '800px'}}>NonprofitsHQ General Terms Of Service</p> */}
                                        <div className="breadcrumb-wrap">

                                    <nav aria-label="breadcrumb">

                                    </nav>
                                </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                {/* content container */}
                <div className='content-container'>
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            NonprofitsHQ's SLA & Security Policy
                                        </h4>
                                        <p>This SLA and Security Policy sets forth certain additional service level and security policies applicable to the proprietary cloud-based software-as-a-service platform(s) made available through the Site and/or any NonprofitsHQ mobile application (each a “NonprofitsHQ Product” and, collectively, the “NonprofitsHQ Products”) offered by NonprofitsHQ, Inc, directly or through any of its affiliated companies including, without limitation, “NonprofitsHQ“, “we” or “us” to you, our end-users “Customer“, “you” or “your”. This SLA and Security Policy is subject to the NonprofitsHQ General Terms of Service made available at <a href="/policy/tos">https://nonprofitshq.com/policy/tos</a> (the “Terms of Service”) and forms part of the Agreement between you and NonprofitsHQ. Capitalized terms that are not defined in this SLA and Security Policy will have the same meaning as in the Terms of Service.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* PERFORMANCE CRITERIA */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            <strong>PERFORMANCE CRITERIA</strong>
                                        </h4>
                                        <p>NonprofitsHQ uses commercially reasonable efforts designed to ensure that the NonprofitsHQ Products provide a monthly uptime of 99.9% of the time during the applicable Sales Order Term except for periods of scheduled downtime for routine maintenance and service (the “Uptime Commitment”). Scheduled maintenance shall not exceed eight (8) hours per month and NonprofitsHQ will make good faith efforts to schedule maintenance during the hours of 9pm – 5am PST. Any period during which the NonprofitsHQ Products are not reasonably available to Customer or its Authorized Users that falls below the Uptime Commitment will be considered “Downtime” except as specifically described herein. Should Downtime occur, as Customer’s sole and exclusive remedy and NonprofitsHQ’s sole and exclusive liability, NonprofitsHQ shall have qualified personnel respond promptly to a report of such unavailability and shall, to the extent reasonably practicable, work continuously to remedy such unavailability. The Uptime Commitment does not apply if Customer or its Authorized Users cannot access or utilize the NonprofitsHQ Products because of (a) any latency or downtime due to Customer’s or its Authorized Users’ acts or omissions or resulting from the their own Internet Service Provider, (b) acts of unauthorized third parties, (c) scheduled maintenance, (d) third party acts or omissions over which NonprofitsHQ has no control, (e) a force majeure event (including, without limitation, a distributed denial of service (DDoS) attack); (f) any systemic Internet failures; or (g) any failure or deficiency in the Customer’s or its Authorized Users’ own hardware, software or network connection.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* POST-IMPLEMENTATION SUPPORT SERVICES */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            <strong>POST-IMPLEMENTATION SUPPORT SERVICES</strong>
                                        </h4>
                                        <p>NonprofitsHQ may provide Customer with Support Services as specified in a Sales Order or the support package(s) subsequently purchased by Customer. Support Services will only be provided by NonprofitsHQ to Customer’s authorized administrators for the NonprofitsHQ Products. It is the Customer’s sole responsibility to provide primary support to its Authorized Users. NonprofitsHQ may at its option provide secondary support for Customer’s Authorized Users, and in any such case such secondary support shall be in accordance with a separate agreement agreed to by the Parties (in writing). If, during the course of providing any secondary support to an Authorized User, NonprofitsHQ determines that the scope of the support sought by such Authorized User is outside of the scope of the Support Services agreed by the Parties, NonprofitsHQ may cease providing such secondary support and direct such Authorized User to contact Customer for assistance.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECURITY */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            <strong>SECURITY</strong>
                                        </h4>
                                        <p>NonprofitsHQ utilizes Google Cloud Platform (“GCP”) for server hosting in connection with the NonprofitsHQ Products. GCP provides highly secure data centers that use state-of-the art electronic surveillance and multi-factor access control systems. For more information on GCP please go to: <a href="https://cloud.google.com/security?hl=en">https://cloud.google.com/security?hl=en</a>. NonprofitsHQ will use commercially reasonable efforts to maintain database security for Customer Data in NonprofitsHQ’s possession or control. NonprofitsHQ uses SSL technology and Server Digital Certificates to encrypt sensitive data traffic over the Internet. Data replication is over private connections. The database is not directly accessible by IP address from the public Internet. NonprofitsHQ employs industry standard network security techniques which may include, firewalls, VLAN’s and NT/UNIX authentication protocols. NonprofitsHQ reserves the right, in its sole discretion, to change or modify these procedures at any time for commercially reasonable purposes, but at all times will maintain commercially reasonable database security. Customer shall take all commercially reasonable security precautions to prevent unauthorized or fraudulent use of the NonprofitsHQ Products and Services by Customer, Customer’s Authorized Users, employees or agents, or any other third party.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
            <Footer/>
    </Fragment>
    )
};
export default SlaSecurityPage;