import React, { useState } from "react";

import {
  Row,
  Col,
  FormGroup,
  Input,
  FormFeedback,
  Form,
  Alert,
  Card,
  CardBody
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { findLogin } from "../helpers/backend_helper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const FindLoginForm = ({setLoginDetails}:any) => {

    const [error, setError]:any = useState();

  // Form validation 
  const validation : any= useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Enter a valid email.").required("Email is required."),
    }),
    onSubmit: async (values :any) => {
        try {
            let response;
            response = findLogin(values);
            const data = await response;
            if(data) {
                setLoginDetails(data);
            }
        } catch(error:any) {
            setError(error?.response?.status);
        }
    }
  });
  

  return (
    <React.Fragment>
          <Row>
            <Col xl="12">

                <Card>
                    <CardBody>

                        { error && (<Alert color='danger'>We couldn't find an account associated with that email.</Alert>)}

                        <p className="page-title"><span style={{marginRight: '15px'}}><FontAwesomeIcon icon={icon({name: 'chevron-right'})} /></span>Find your organization</p>
                        <p>Each organization on NonprofitsHQ gets a unique login URL to access the Account Portal. If you need help finding where to login, enter your email below.</p>

                        <Form className="needs-validation" onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}>
                            <Row>
                                <FormGroup className="mb-3">
                                    <Input
                                        name="email"
                                        placeholder="Email address"
                                        type="text"
                                        className="form-control"
                                        style={{fontSize: '18px', padding: '10px', height: '60px'}}
                                        id="email"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email || ""}
                                        invalid={
                                            validation.touched.email && validation.errors.email ? true : false
                                        }
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Row>

                        

                                <button className="navbar-demo-btn btn btn-primary btn-sm" type="submit" style={{position: 'relative', float: 'right'}}>
                                    Get Login Info
                                </button>
                        
                        </Form>
                    </CardBody>
                </Card>
            </Col> 
        </Row>
    </React.Fragment>
  );
};

export default FindLoginForm;
