import React, {Fragment} from 'react';
import Header from '../../components/header'
import Footer from '../../components/footer'
import './style.css';

import HubspotForm from "../../components/HubspotForm";
import SliderOffice from '../../images/slider/slide1.png';
import settings from '../../images/svg/orange/settings-svgrepo-com.svg';
import widget from '../../images/svg/orange/widget-add-svgrepo-com.svg';
import like from '../../images/svg/orange/like-svgrepo-com.svg';
import clipboard from '../../images/svg/blue/clipboard-list-svgrepo-com.svg';
import user from '../../images/svg/blue/user-svgrepo-com.svg';
import dollaBill from '../../images/svg/blue/dollar-svgrepo-com.svg';
import graphUp from '../../images/svg/blue/graph-up-svgrepo-com.svg';
import handStars from '../../images/svg/blue/hand-stars-svgrepo-com.svg';
import calendar from '../../images/svg/blue/calendar.svg';
import CallToAction from '../../components/CallToAction';

const HomePage =() => {

    document.title = "Nonprofit management made easy | NonprofitsHQ"
    return(
        <Fragment>
            <Header/>
                <main>
                <section id="home" className="slider-area slider-three fix p-relative">

                    <div className="slider-active">
                    <div className="single-slider slider-bg d-flex align-items-center pt-100 pb-100" style={{background: `url(${SliderOffice}) no-repeat`, backgroundSize: 'cover'}}>
                        <div className="gradient-overlay"></div>
                            <div className="container">
                            <div className="row align-items-center">
                                    <div className="col-lg-7">
                                        <div className="slider-content s-slider-content text2">
                                            <h2 data-animation="fadeInUp" data-delay=".4s">Amplify your impact with NonprofitsHQ</h2>
                                            <p style={{fontSize: '22px'}} data-animation="fadeInUp" data-delay=".6s">NonprofitsHQ is everything you need to manage your nonprofit while saving time and money.</p>

                                            <div className="slider-btn mt-40 mb-100">
                                                <a href="/contact" className="btn ss-btn active mr-15" data-animation="fadeInRight" data-delay=".8s">Learn More</a>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="slider-img" data-animation="fadeInUp" data-delay=".4s">
                                                <div style={{backgroundColor: '#fff', padding: '30px', borderRadius: '15px'}}>
                                                    <h3>Request a Demo</h3>
                                                    <p style={{marginBottom: '20px'}}>Learn how NonprofitsHQ can help you save time and money.</p>

                                                    <HubspotForm 
                                                        region="na1"
                                                        portalId="45028631"
                                                        formId='90064b97-6ee2-4845-8ffe-867751f33ec1'
                                                    />
                                                </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        </div>


                </section>

                <section className="services-three pt-100 pb-70">
                    <div className="container">
                        <div className="section-title center-align mb-50 text-center">
                            <h2>
                                Don't Settle For Less
                            </h2>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="s-single-services text-center">
                                    <div className="services-icon">
                                        <img src={settings} className="services-icon-image" alt=''/>
                                    </div>

                                    <div className="second-services-content">
                                        <h5>Simple</h5>
                                        <p>An easy onboarding process, keep the lights on.</p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                            <div className="s-single-services text-center">
                                    <div className="services-icon">
                                    <img src={widget} className="services-icon-image" alt='widget'/>
                                    </div>

                                    <div className="second-services-content">
                                        <h5>Centralized</h5>
                                        <p>Stop app hopping, use a single tool catered to nonprofits.</p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="s-single-services text-center">
                                    <div className="services-icon">
                                        <img src={like} className="services-icon-image" alt="like"/>
                                    </div>
                                    <div className="second-services-content">
                                        <h5>Affordable</h5>
                                        <p>Finally a truly easy to use tool that adapts to your needs.</p>
                                    </div>

                                </div>
                            </div>




                        </div>

                    </div>
                </section>

                <section id="ingredient" className="services-area pt-100 pb-70 fix" style={{backgroundColor: '#075272ff'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title center-align mb-50 text-left">
                                    <h2 style={{color: '#fff'}}>
                                    All-In-One Platform
                                    </h2>
                                </div>

                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-12">

                            <div className="services-box wow fadeInUp  animated mb-30"  data-delay=".4s">
                                <div className="services-icon">
                                    <img src={dollaBill} alt="icon01" style={{height: '50px'}} />
                                </div>
                                <div className="services-content2">
                                        <h5 className="negative-heading">Fundraising</h5>
                                        <p className="light-gray-heading">Create fundraising campaigns with a few simple clicks, then share it with everyone.</p>
                                </div>
                            </div>

                            <div className="services-box wow fadeInUp  animated mb-30"  data-delay=".5s">
                                <div className="services-icon">
                                    <img src={clipboard} style={{height: '50px'}} alt='clipboard'/>
                                </div>
                                <div className="services-content2">
                                        <h5 className="negative-heading">Policy</h5>
                                        <p className="light-gray-heading">Keep your organization up-to-date with local policies and regulations.</p>
                                </div>
                            </div>

                            </div>

                            <div className="col-lg-4 col-md-12">
                                <div className="services-box wow fadeInUp  animated mb-30"  data-delay=".6s">
                                <div className="services-icon">
                                    <img src={graphUp} alt="impact-tracking-icon" style={{height: '50px'}}/>
                                </div>
                                <div className="services-content2">
                                        <h5 className="negative-heading">Impact</h5>
                                        <p className="light-gray-heading">Track how your organization is changing your community. Observe and strengthen your approach.</p>
                                </div>
                                </div>

                                <div className="services-box wow fadeInUp  animated mb-30 text-left"  data-delay=".7s">
                                <div className="services-icon">
                                    <img src={user} style={{height: '50px'}} alt='user'/>
                                </div>

                                <div className="services-content2">
                                    <h5 className="negative-heading">People</h5>
                                    <p className="light-gray-heading">Manage staff and volunteers from a single screen.</p>
                                </div>
                                </div>

                                <div className="pro-img">

                                </div>
                            </div>

                            <div className="col-lg-4 col-md-12">

                            <div className="services-box wow fadeInUp  animated mb-30 text-left"  data-delay=".8s">
                                <div className="services-icon">
                                    <img src={calendar} alt="events-icon" style={{height: '50px'}}/>
                                </div>

                                <div className="services-content2">
                                    <h5 className="negative-heading">Events</h5>
                                    <p className="light-gray-heading">Plan events, create tickets and QR codes all in one place. </p>
                                </div>
                            </div>



                            <div className="services-box wow fadeInUp  animated mb-30 text-left"  data-delay=".9s">
                                <div className="services-icon">
                                    <img src={handStars} alt="board-portal-icon" style={{height: '50px'}}/>
                                </div>

                                <div className="services-content2">
                                    <h5 className="negative-heading">Board Portal</h5>
                                    <p className="light-gray-heading">Run effective board meetings, video conferences, and more</p>
                                </div>
                            </div>

                            </div>

                        </div>
                    </div>
                </section>
                
                <section  className="product-area pt-100 pb-70 fix" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title center-align mb-50 text-center">
                                    <h2>
                                        Affordable Solutions
                                    </h2>
                                </div>

                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                            <div className="pricing-box pricing-box2 text-center mb-60  wow fadeInLeft  animated">
                                    <div className="pricing-head">
                                        <h3>Essentials</h3>
                                    <div className="price-count">
                                            <h2>$499 <strong>/m</strong></h2>
                                            <p style={{fontSize: '12px', fontWeight: '300'}}>Billed Annually</p>
                                        </div>
                                    </div>
                                    <div className="pricing-body mt-30 mb-10 text-center">
                                        <p>Perfect for smaller organizations that need powerful tools to make their organization efficient. <a href="/pricing">See all features.</a></p>
                                    </div>
                                    <div className="pricing-btn mb-60">
                                        <a href="/demo" className="btn ss-btn">Request a Demo</a>
                                    </div>
                                </div>
                            </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="pricing-box pricing-box2 active text-center mb-60  wow fadeInUp  animated">
                                    <div className="pricing-head">
                                        <h3>Impact</h3>
                                    <div className="price-count">
                                            <h2>$999 <strong>/m</strong></h2>
                                            <p style={{fontSize: '12px', fontWeight: '300'}}>Billed Annually</p>
                                        </div>
                                    </div>
                                    <div className="pricing-body mt-30 mb-10 text-center">
                                        <p>Ideal for medium sized nonprofits that need more power and have a little more budget. <a href="/pricing">See all features.</a></p>
                                    </div>
                                    <div className="pricing-btn mb-60">
                                        <a href="/demo" className="btn ss-btn">Request a Demo</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="pricing-box pricing-box2 text-center mb-60  wow fadeInRight  animated">
                                        <div className="pricing-head">
                                            <h3>Amplify</h3>
                                        <div className="price-count">
                                                <h2>Contact</h2>
                                                <p style={{fontSize: '12px', fontWeight: '300'}}>Customized Solution</p>
                                            </div>
                                        </div>
                                        <div className="pricing-body mt-30 mb-10 text-center">
                                        <p>Best for larger nonprofits that need customized features and workflows to achieve their goals. <a href="/pricing">See all features.</a></p>
                                        </div>
                                        <div className="pricing-btn mb-60">
                                            <a href="/demo" className="btn ss-btn">Request a Demo</a>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </section>

                <CallToAction />
            </main>
            <Footer/>
        </Fragment>
    )
};
export default HomePage;