import React, {Fragment} from 'react';
import Header from '../../components/header'
import Footer from '../../components/footer'
import './style.css';


import eventsGraphic from '../../images/screenshots/EventOverview.png';
import eventsGraphic2 from '../../images/screenshots/EventTicketsPurchase.png';
import whiteSectionBackground from '../../images/bg/white-with-squares.png';
// import FormationStepsEdited from '../../images/screenshots/FormationStepsEdited.png';
// import FundraisingTransactionsEdited from '../../images/screenshots/FundraisingTransactionsEdited.png';
// import HqDashboard from '../../images/screenshots/HqDashboard.png';
// import FundraisingCampaignsEdited from '../../images/screenshots/FundraisingCampaignsEdited.png';
import pageTitleBackground from '../../images/titles/EventsPageTitle.png';
import CallToAction from '../../components/CallToAction';

const EventsPage =() => {

    document.title = "Event Management | NonprofitsHQ"
    return(
        <Fragment>
            <Header/>
                <main>
                <section className="breadcrumb-area d-flex align-items-center parallax" style={{background: `url(${pageTitleBackground}) no-repeat`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-12">
                                <div className="breadcrumb-wrap text-left">
                                    <div className="breadcrumb-title mb-30">
                                        <h2>Events</h2>
                                        <p style={{fontSize: '22px', color: '#fff', maxWidth: '800px'}}>Create, manage, and market your events with NonprofitsHQ.</p>
                                        <div className="breadcrumb-wrap">

                                    <nav aria-label="breadcrumb">

                                    </nav>
                                </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <div className="counter-area pt-60 pb-40 parallax">
                    <div className="container">
                        <div className="row wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-lg-6">
                                <div className="section-title center-align mb-40 text-left">
                                    <h2 className="secondary-heading">
                                        Manage your events like a pro with NonprofitsHQ
                                    </h2>
                                    <p>
                                        Empower your fundraising initiatives effortlessly with our all-in-one event management and ticketing platform. Seamlessly create, promote, and manage your fundraising events while effortlessly selling tickets and engaging attendees. From free to paid events, ticket sales to seamless execution, on-site support, and beyond. Schedule a demo now to discover how NonprofitsHQ can help you craft remarkable events.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="section-title center-align mb-6 text-left">
                                    <img src={eventsGraphic} alt='eventsGraphic'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <section className='blue-section d-flex justify-content-center pb-50'>
                    <div className="container mt-5">
                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-md-12 mb-4 text-center">
                                <h2>Seamlessly create and manage memorable events through:</h2>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                        <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Easy Ticketing</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Event Creation</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Check-In Management</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">QR Code Generator</p>
                                    </div>
                                </div>
                            </div>                         

                        </div>
                    </div>
                </section>

                <section className='bootstrap-section pt-60 pb-40' style={{background: `url(${whiteSectionBackground}) no-repeat`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-md-5">
                                <div className="left-side">
                                    <div className="section-title center-align mb-6 text-left">
                                        <img src={eventsGraphic2} alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="right-side">
                                    <div className="section-title center-align mb-6 text-left" style={{background: 'rgba(255,255,255, .9)', padding: '5px', borderRadius: '15px'}}>
                                        <h2 className="secondary-heading">
                                            Seamless event planning and management.
                                        </h2>
                                        <p>
                                            Creating unforgettable events are at the heart of all nonpofits, and with the right tool creating meaningful events is a breeze.
                                            NonprofitsHQ helps organizations create, manage, and deliver strong impactful events from a single platform. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <CallToAction />
                
            </main>
        <Footer/>
    </Fragment>
    )
};
export default EventsPage;