import React, {Fragment} from 'react';
import Header from '../../components/header'
import MeetZac from '../../images/zac/zac.jpg'
import Footer from '../../components/footer'

import HubspotForm from "../../components/HubspotForm";

import pageTitleBackground from '../../images/titles/MeetPageTitle.png';


const MeetZacPage =() => {

    document.title = "Meet Zac | NonprofitsHQ"

    return(
        <Fragment>
            <Header/>
            <main>
                <section className="breadcrumb-area d-flex align-items-center parallax" style={{background: `url(${pageTitleBackground}) no-repeat`, backgroundSize: 'cover'}}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="breadcrumb-wrap text-left">
                                        <div className="breadcrumb-title mb-30">
                                            <h2>Meet Zac</h2>
                                            <p style={{fontSize: '22px', color: '#fff', maxWidth: '800px'}}>Let's Make Nonprofit Management Easy</p>
                                            <div className="breadcrumb-wrap">

                                        <nav aria-label="breadcrumb">

                                        </nav>
                                    </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <div className="counter-area pt-60 pb-40 parallax">
                        <div className="container">
                            <div className="row">
                                <div className='col-lg-4'>
                                    
                                        <div className="section-title center-align mb-40 text-left">
                                            <div className="section-title center-align mb-6 text-left" style={{borderRadius: '10px', border: '5px solid #075272ff'}}>
                                                <img src={MeetZac} alt='meetzac' />
                                            </div>
                                            
                                        </div>
                                    
                                </div>

                                <div className='col-lg-4'>
                                    
                                            <div className="section-title center-align mb-40 text-left">
                                                <p>
                                                    Drawing on his software engineering background and dedication to social change, Zac, Founder & CEO of NonprofitsHQ, empowers nonprofit organizations. His experience leading The Bytes Foundation highlighted the demand for impactful technology, leading Zac to establish NonprofitsHQ. This platform unites purpose-built technology with mission-driven individuals, amplifying the impact of nonprofits nationwide.
                                                </p>
                                                <div className="footer-social  mt-30 mb-15">                                    
                                                    <a href="mailto:zac@nonprofitshq.com"><i className="fa fa-envelope"></i></a>
                                                    <a href="https://twitter.com/zacjordanbrown"><i className="fab fa-twitter"></i></a>
                                                    <a href="https://linkedin.com/in/zacjordanbrown"><i className="fab fa-linkedin"></i></a>
                                                </div>
                                                
                                            </div>
                                    
                                </div>

                                

                                <div className="col-lg-4">

                                        <div className="section-title center-align mb-6 text-left" style={{padding: '25px', borderRadius: '15px', border: '2px solid #f6f9fdff'}}>
                                            <h4 className='secondary-heading'>Request a Demo</h4>
                                            <HubspotForm 
                                                region="na1"
                                                portalId="45028631"
                                                formId='90064b97-6ee2-4845-8ffe-867751f33ec1'
                                            />
                                        </div>

                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
                <Footer/>
        </Fragment>
    )
};
export default MeetZacPage;
