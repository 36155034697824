import React, {Fragment, useState} from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import './style.css';

import pageTitleBackground from '../../images/titles/EventsPageTitle.png';
import whiteSectionBackground from '../../images/bg/white-with-squares.png';

import FindLoginForm from '../../forms/FindLoginForm';
import {
    Tooltip,
    Card,
    CardBody
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const LoginPage =() => {
    const [loginDetails, setLoginDetails]:any = useState();
    const [showCopied, setShowCopied]:any = useState();

    const activateTooltip = function() {
        setShowCopied(true);
        setTimeout(() => {
            setShowCopied(false);
        }, 1000);
    }

    document.title = "Login | NonprofitsHQ"

    return(
        <Fragment>
            <Header/>
                <main>
                    <section className="breadcrumb-area d-flex align-items-center parallax" style={{background: `url(${pageTitleBackground}) no-repeat`, backgroundSize: 'cover'}}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="breadcrumb-wrap text-left">
                                        <div className="breadcrumb-title mb-30">
                                            <h2>Login</h2>
                                            <p style={{fontSize: '22px', color: '#fff', maxWidth: '800px'}}>NonprofitsHQ makes nonprofit management easy.</p>
                                            <div className="breadcrumb-wrap">

                                        <nav aria-label="breadcrumb">

                                        </nav>
                                    </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section className='bootstrap-section pt-60 pb-40 mb-25' style={{background: `url(${whiteSectionBackground}) no-repeat`, backgroundSize: 'cover'}}>
                        <div className="container">
                            <div className="row wow fadeInUp animated"  data-delay=".1s">
                                <div className="row">
                                { !loginDetails && (<div className="col-lg-6 offset-lg-3">
                                    <FindLoginForm setLoginDetails={setLoginDetails}/>
                                </div>)}

                                { loginDetails && (<div className="col-lg-6 offset-lg-3">

                                    <Card>
                                        <CardBody>
                                            <p className="page-title">{ loginDetails?.organization?.name} </p>

                                            <p>Use this URL to access the Account Portal:</p>

                                            <Tooltip placement="top" isOpen={showCopied} target="campaign_url">Copied to clipboard!</Tooltip>
                                            <p 
                                            id="campaign_url" 
                                            style={{backgroundColor: '#f0f0f0', cursor: 'pointer', padding: '10px', borderRadius: '5px'}}
                                            onClick={() => {navigator.clipboard.writeText(`https://${loginDetails?.organization?.tenant}.nonprofitshq.org/`); activateTooltip();}}>
                                            <span><FontAwesomeIcon icon={icon({name: 'copy'})} /></span> {`https://${loginDetails?.organization?.tenant}.nonprofitshq.org/`}
                                            </p>

                                            <p className="login-details-subtext">This is the URL you will use to login to your { loginDetails?.organization?.name } account on NonprofitsHQ. If you are still having trouble logging in, please reach out to { loginDetails?.organization?.name } for further assistance.</p>

                                            <p className="login-actions-title">Helpful Tips:</p>

                                            <div className="login-tips">
                                                <ul>
                                                    <li><span><FontAwesomeIcon icon={icon({name: 'check'})} /></span> After you navigate to the portal for { loginDetails?.organization?.name }, save the page to your browser bookmarks so you wont forget it.</li>
                                                    <li><span><FontAwesomeIcon icon={icon({name: 'check'})} /></span> If you're on your phone or other mobile device, you can <a href="https://www.brandeis.edu/its/support/website-shortcut.html" target="_blank" rel="noreferrer">save this Account Portal to your desktop</a> for easier access.</li>
                                                </ul>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>)}

                            </div>
                            </div>
                        </div>
                    </section>



            </main>
        <Footer/>
    </Fragment>
    )
};
export default LoginPage;