import React from 'react'

import './style.css'

import clipboardList from '../../images/svg/blue/clipboard-list-svgrepo-com.svg';
import logo from '../../images/logo/logo.png';
import user from '../../images/svg/blue/user-svgrepo-com.svg';
import dollaBill from '../../images/svg/blue/dollar-svgrepo-com.svg';
import graphUp from '../../images/svg/blue/graph-up-svgrepo-com.svg';
import  handStars from '../../images/svg/blue/hand-stars-svgrepo-com.svg';
import calendar from '../../images/svg/blue/calendar.svg';

const Header = () => {
    return(	
        <header className="header-area header-area2">
            <div className="header-top second-header d-none d-lg-block">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-4 d-none d-lg-block">
                            <div className="header-social">
                                <span>
                                    <a href="https://www.facebook.com/nonprofitshq" title="Facebook"><i className="fab fa-facebook-f"></i></a>
                                    <a href="https://twitter.com/nonprofitshq" title="Twitter"><i className="fab fa-twitter"></i></a>
                                    <a href="https://www.linkedin.com/company/nonprofitshq/" title="LinkedIn"><i className="fab fa-linkedin-in"></i></a>

                                </span>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 d-none  d-md-block">
                            <div className="header-cta text-right">
                                <ul>
                                    <li>
                                        <a href="mailto:hello@nonprofitshq.com">hello@nonprofitshq.com</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="header-sticky" className="menu-area pt-15">
                
                {/* desktop navbar */}
                <div className="container d-none d-lg-block ">
                    <div className="second-menu">
                        <div className="row align-items-center">
                            
                            {/* logo */}
                            <div className="col-xl-2 col-lg-2">
                                <div className="logo">
                                    <a href="/"><img src={logo} alt="NonprofitsHQ logo" /></a>
                                </div>
                            </div>


                            {/* Navbar */}
                            <div className="col-xl-10 col-lg-10">
                                <div className="main-menu text-left text-xl-right">
                                    <nav id="mobile-menu">
                                        <ul>
                                            <li className="has-sub">
                                                <a href="/">Home</a>
                                            </li>
                                            <li className="has-sub"><a href="#">Product</a>
                                                <ul>
                                                    <div className="drop-down-heading">
                                                        <h4>The Solution is Simple:</h4>
                                                        <p style={{color: '#73726fff'}}>
                                                            A Centralized Nonprofit Management Platform
                                                        </p>
                                                    </div>
                                                    <li>
                                                        <a href="/product/policy">
                                                            <img src={clipboardList} className="drop-down-icon" alt='clipboard'/>
                                                            Policy
                                                            <p className="dropdown-descriptor">Store & Update Policies</p>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/product/people">
                                                            <img src={user} className="drop-down-icon" alt='usericon'/>
                                                            People
                                                            <p className="dropdown-descriptor">Staff & Volunteer Management</p>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/product/fundraising">
                                                            <img src={dollaBill} className="drop-down-icon" alt='dollaricon'/>
                                                            Fundraising
                                                            <p className="dropdown-descriptor">Start Taking Donations</p>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/product/impact">
                                                            <img src={graphUp} className="drop-down-icon" alt='graphicon'/>
                                                            Impact
                                                            <p className="dropdown-descriptor">Track Your Success</p>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/product/board">
                                                            <img src={handStars} className="drop-down-icon" alt='handstaricon'/>
                                                            Board Portal
                                                            <p className="dropdown-descriptor">Run Productive Board Meetings</p>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/product/events">
                                                            <img src={calendar} className="drop-down-icon" alt='calendaricon'/>
                                                            Events
                                                            <p className="dropdown-descriptor">Easy Ticketing & Eventing</p>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li><a href="/pricing">Pricing</a></li>
                                            <li><a href="/company/values">Our Values</a></li>
                                            <li><a href="/contact">Contact Us</a></li>
                                            <li><a href="/login">Login</a></li>
                                            <li><a href="/demo" className="btn ss-btn">Request a Demo</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>



                                <div className="col-12">
                                    <div className="mobile-menu"></div>
                                </div>
                        </div>
                    </div>
                </div>     

                <nav className="navbar navbar-expand-lg navbar-light d-lg-none">
                    <div className="logo">
                        <a href="/"><img src={logo} alt="NonprofitsHQ logo" /></a>
                    </div>
                    <button style={{marginRight: "20px"}} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse bootstrap-mobile-menu" id="navbarSupportedContent">
                        <ul>
                            <li className="has-sub">
                                <a href="/">Home</a>
                            </li>
                            <li className="has-sub" data-toggle="collapse" data-target='#productSupportedContent' aria-controls="productSupportedContent" aria-expanded="false" aria-label='Toggle'><a href="#">Product</a>
                                <ul className="collapse" id="productSupportedContent">
                                    <div className="drop-down-heading">
                                        <h4>The Solution is Simple:</h4>
                                        <p style={{color: '#73726fff'}}>
                                            A Centralized Nonprofit Management Platform
                                        </p>
                                    </div>
                                    <li>
                                        <a href="/product/policy">
                                            <img src={clipboardList} className="drop-down-icon" alt='clipboard'/>
                                            Policy
                                            <p className="dropdown-descriptor">Store & Update Policies</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/product/people">
                                            <img src={user} className="drop-down-icon" alt='usericon'/>
                                            People
                                            <p className="dropdown-descriptor">Staff & Volunteer Management</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/product/fundraising">
                                            <img src={dollaBill} className="drop-down-icon" alt='dollaricon'/>
                                            Fundraising
                                            <p className="dropdown-descriptor">Start Taking Donations</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/product/impact">
                                            <img src={graphUp} className="drop-down-icon" alt='graphicon'/>
                                            Impact
                                            <p className="dropdown-descriptor">Track Your Success</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/product/board">
                                            <img src={handStars} className="drop-down-icon" alt='handstaricon'/>
                                            Board Portal
                                            <p className="dropdown-descriptor">Run Productive Board Meetings</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/product/events">
                                            <img src={calendar} className="drop-down-icon" alt='calendaricon'/>
                                            Events
                                            <p className="dropdown-descriptor">Easy Ticketing & Eventing</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="/pricing">Pricing</a></li>
                            <li><a href="/company/values">Our Values</a></li>
                            <li><a href="/contact">Contact Us</a></li>
                            <li><a href="/login">Login</a></li>
                            <li><a href="/demo" style={{color: "#ef692aff"}}>Request a Demo</a></li>
                        </ul>
                    </div>
                </nav>

            </div>
        </header>
    )
}

export default Header;