import React, {Fragment} from 'react';
import Header from '../../components/header'
import Footer from '../../components/footer'
import CalloutBox from '../../components/CalloutBox';
import './style.css';

import {
    Row,
    Col,
    Container
} from "reactstrap";

import valueGraphic1 from '../../images/bg/InclusiveAi.png'
import valueGraphic2 from '../../images/bg/Accountability.png'
import valueGraphic3 from '../../images/bg/Privacy.png'
import valueGraphic4 from '../../images/bg/Justice.png'
import valueGraphic5 from '../../images/bg/SafetyReliable.png'
import valueGraphic6 from '../../images/bg/ContinuousImprovement.png'


import pageTitleBackground from '../../images/titles/AiPageTitle.png';
import whiteSectionBackground from '../../images/bg/white-with-squares.png'
import CallToAction from '../../components/CallToAction';

const ResponsibleAiPage =() => {

    document.title = "Responsible AI | NonprofitsHQ"

    return(
        <Fragment>
            <Header/>
                    <main>
                    <section className="breadcrumb-area d-flex align-items-center parallax" style={{background: `url(${pageTitleBackground}) no-repeat`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-12">
                                <div className="breadcrumb-wrap text-left">
                                    <div className="breadcrumb-title mb-30">
                                        <h2>Responsible Ai Pledge</h2>
                                        <p style={{fontSize: '22px', color: '#fff', maxWidth: '800px'}}>As creators, developers, and users of Artificial Intelligence (AI) systems, NonprofitsHQ solemnly pledges to uphold the following principles:</p>
                                        <div className="breadcrumb-wrap">

                                    <nav aria-label="breadcrumb">

                                    </nav>
                                </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                    <div className="counter-area pt-60 pb-40 parallax" style={{background: `url(${whiteSectionBackground}) no-repeat`, backgroundSize: 'cover'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12" style={{background: 'rgba(255,255,255, 1)', padding: '15px', borderRadius: '15px'}}>
                                    <div className="section-title center-align mb-5 text-left">
                                    <Container style={{marginBottom: '50px', marginTop: '0px'}}>     
                                        <Row style={{marginBottom: '25px'}}>
                                            <Col md={'4'} className='justify-content-center wow fadeInUp animated pb-25'>
                                                <CalloutBox title="Inclusive Ethical Approach" text="We commit to developing AI technologies that respect human rights, diversity, and dignity. Our AI systems will not be designed or used to discriminate against individuals or groups based on race, gender, ethnicity, religion, sexual orientation, or any other characteristic." image={valueGraphic1}/>
                                            </Col>
                                            <Col md={'4'} className='justify-content-center wow fadeInUp animated mb-25'>
                                                <CalloutBox title="Transparency and Accountability" text="We will strive for transparency in the development and deployment of AI systems. We will be accountable for the decisions made by these systems and ensure they are understandable, explainable, and verifiable to users and stakeholders." image={valueGraphic2}/>
                                            </Col>
                                            <Col md={'4'} className='justify-content-center wow fadeInUp animated mb-25'>
                                                <CalloutBox title="Privacy and Data Security" text="We will prioritize the protection of user privacy and data security. Our AI systems will collect and handle data responsibly, ensuring consent, confidentiality, and protection against unauthorized access or misuse." image={valueGraphic3}/>
                                            </Col>
                                        </Row> 

                                        <Row pb={'25'} pt={'500'} mt={'500'}>
                                            <Col md={'4'} className='justify-content-center wow fadeInUp animated mb-25'>
                                                <CalloutBox title="Fairness and Justice" text="We will work to mitigate biases in AI algorithms and datasets, aiming for fairness and equity in outcomes. We will continuously assess and address potential biases, striving to create AI that serves all communities and individuals fairly." image={valueGraphic4}/>
                                            </Col>
                                            <Col md={'4'} className='justify-content-center wow fadeInUp animated mb-25'>
                                                <CalloutBox title="Safety and Reliability" text="We are dedicated to ensuring the safety, reliability, and robustness of AI systems. We will implement safeguards to prevent harm, prioritize system resilience, and regularly test and improve our AI for reliability in various scenarios." image={valueGraphic5}/>
                                            </Col>
                                            <Col md={'4'} className='justify-content-center wow fadeInUp animated mb-25'>
                                                <CalloutBox title="Constant Improvement" text="We commit to continuous learning and improvement in AI development practices. We will adapt to emerging ethical challenges, seek feedback, and update our processes to align with evolving ethical standards." image={valueGraphic6}/>
                                            </Col>
                                        </Row> 
                                    </Container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <CallToAction />

                </main>
            <Footer/>
        </Fragment>
    )
};
export default ResponsibleAiPage;