import React from 'react'
import background from '../../images/bg/happy-people-overlay.png';

const CallToAction = () => {

    return(
        <section className="hero hero-style-2">
            <div className="counter-area pt-60 pb-40 " style={{backgroundImage: `linear-gradient(90deg, rgba(239, 105, 42, 0.6), rgba(7, 82, 114, 0.6)), url(${background})`}} >
                <div className="container">
                    <div className="row wow fadeInUp animated"  data-delay=".1s">
                        <div className="col-lg-12">
                            <div className="section-title center-align mb-40 text-center">
                                <h2 style={{ color: '#fff'}}>
                                    Nonprofit Management Made Easy
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="section-title center-align mb-5 text-center">
                                <p style={{color: '#fff'}}>
                                Maximize the potential of your organization with NonprofitsHQ – the comprehensive solution for managing your nonprofit's operations. Say goodbye to fragmented workflows and embrace efficiency with our unified management tool.
                                </p>
                                <div className="slider-btn mt-40">
                                            <a href="/demo" className="btn ss-btn active mr-15" data-animation="fadeInRight" data-delay=".8s">Request a Demo</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CallToAction;