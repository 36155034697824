import React from 'react';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Homepage from '../HomePage'
import ErrorPage from '../ErrorPage'  
import MeetZacPage from '../meetZacPage';

import Alberto from '../meetAlbertoPage';
import BoardPortalPage from '../BoardPortalPage';
import ContactPage from '../ContactPage';
import FundRaisingPage from '../FundRaisingPage';
import EventsPage from '../EventsPage';
import ImpactPage from '../ImpactPage';
import JoinWaitlistPage from '../JoinWaitlistPage';
import PeoplePage from '../PeoplePage';
import PolicyPage from '../PolicyPage';
import PricingPage from '../PricingPage';
import PrivacyPage from '../PrivacyPage';
import ResponsibleAiPage from '../ResponsibleAiPage';
import SlaSecurityPage from '../SlaSecurityPage';
import TosPage from '../TosPage';
import ValuesPage from '../ValuesPage';
import LoginPage from '../LoginPage';
import SocialsPage from '../SocialPage';
import SupportPage from '../SupportPage';


const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
          <Routes>
            <Route path='/' element={<Homepage />}/>
            <Route path='/zac' element={<MeetZacPage />}/> 
            <Route path='/meet/zac' element={<MeetZacPage />}/>
            <Route path='/404' element={<ErrorPage />}/>
            <Route path="*" element={<ErrorPage />}/>

            <Route path="/meet/alberto" element={<Alberto/>}/>
            <Route path="/product/board" element={<BoardPortalPage />}/>
            <Route path="/company/values" element={<ValuesPage />}/>
            <Route path="/contact" element={<ContactPage />}/>
            <Route path="/login" element={<LoginPage />}/>
            <Route path="/product/events" element={<EventsPage />}/>
            <Route path="/product/fundraising" element={<FundRaisingPage />}/>
            <Route path="/product/impact" element={<ImpactPage />}/>
            <Route path="/demo" element={<JoinWaitlistPage />}/>
            <Route path="/policy/privacy" element={<PrivacyPage />}/>
            <Route path="/policy/tos" element={<TosPage />}/>
            <Route path="/policy/sla_security" element={<SlaSecurityPage />}/>
            <Route path="/product/people" element={<PeoplePage />}/>
            <Route path="/product/policy" element={<PolicyPage />}/>
            <Route path="/pricing" element={<PricingPage />}/>
            <Route path="/company/ai" element={<ResponsibleAiPage />}/>
            <Route path="/socials" element={<SocialsPage />} />
            <Route path="/support" element={<SupportPage />} />
          </Routes>
      </Router>
      
    </div>
  );
}

export default AllRoute;
