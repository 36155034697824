import React, {Fragment} from 'react';
import Header from '../../components/header'
import Footer from '../../components/footer'
import './style.css';


import HubspotForm from "../../components/HubspotForm";
import emailSvgPro from '../../images/svg/blue/email-svgrepo-com.svg';
import locationPinSvg from '../../images/svg/blue/location-pin-svgrepo-com.svg';
import pageTitleBackground from '../../images/titles/WaitlistPageTitle.png';

const Waitlist =() => {

    document.title = "Join the waitlist | NonprofitsHQ"

    return(
        <Fragment>
            <Header/>
                <main>
                       
                <section className="breadcrumb-area d-flex align-items-center parallax" style={{background: `url(${pageTitleBackground}) no-repeat`, backgroundSize: 'cover'}}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="breadcrumb-wrap text-left">
                                        <div className="breadcrumb-title mb-30">
                                            <h2>Request a Demo</h2>
                                            <p style={{fontSize: '22px', color: '#fff', maxWidth: '800px'}}>Tech should always meet people on their terms.</p>
                                            <div className="breadcrumb-wrap">

                                        <nav aria-label="breadcrumb">

                                        </nav>
                                    </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                <div className="counter-area pt-60 pb-40 parallax">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title center-align mb-40 text-left">
                                    <h2 className="secondary-heading">
                                        Run A Smoother Operation Today
                                    </h2>
                                    <div className="text-column col-lg-12 col-md-12 col-sm-12">
                                        <div className="inner-column">
                                            <br/><br/>
                                            <ul className="pr-ul">
                                                <a href="mailto:hello@nonprofitshq.com"><li className="text pt-10"><img src={emailSvgPro} style={{height: '25px', display: 'inline', marginRight: '15px'}} alt="emailIcon"/>hello@nonprofitsHQ</li></a>
                                                <a href="https://www.google.com/maps/place/san+antonio+tx/data=!4m2!3m1!1s0x865c58af04d00eaf:0x856e13b10a016bc?sa=X&ved=2ahUKEwjcr8-BvpOEAxVysDEKHSboAcYQh8EJegQIEBAA" target="_blank" rel="noreferrer"><li className="text pt-10"><img src={locationPinSvg} style={{height: '25px', display: 'inline', marginRight: '15px'}} alt="locationIcon"/>San Antonio, TX</li></a>
                                            </ul>
                                        </div>
                                        <br/>
                                        <div className="footer-social  mt-30">                                    
                                            <a href="https://www.facebook.com/nonprofitshq"><i className="fab fa-facebook-f"></i></a>
                                            <a href="https://twitter.com/nonprofitshq"><i className="fab fa-twitter"></i></a>
                                            <a href="https://www.linkedin.com/company/nonprofitshq/"><i className="fab fa-linkedin"></i></a>
                                        </div>   
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="section-title center-align mb-6 text-left" style={{padding: '25px', borderRadius: '15px', border: '2px solid #f6f9fdff'}}>
                                    <HubspotForm 
                                        region="na1"
                                        portalId="45028631"
                                        formId='90064b97-6ee2-4845-8ffe-867751f33ec1'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </main>
        <Footer/>
    </Fragment>
    )
};
export default Waitlist;