import React, {Fragment} from 'react';
import Header from '../../components/header'
import Footer from '../../components/footer'

import pageTitleBackground from '../../images/titles/SupportPageTitle.png';
import HubspotForm from "../../components/HubspotForm";
import emailSvgPro from '../../images/svg/blue/email-svgrepo-com.svg';
import locationPinSvg from '../../images/svg/blue/location-pin-svgrepo-com.svg';

const SupportPage =() => {

    document.title = "SLA & Security | NonprofitsHQ"
    
    return(
        <Fragment>
            <Header/>
                <main>

                <section className="breadcrumb-area d-flex align-items-center parallax" style={{background: `url(${pageTitleBackground}) no-repeat`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-10">
                                <div className="breadcrumb-wrap text-left">
                                    <div className="breadcrumb-title mb-30">
                                    <h2>Request Support</h2>
                                        <div className="breadcrumb-wrap">

                                    <nav aria-label="breadcrumb">

                                    </nav>
                                </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <div className="counter-area pt-60 pb-40 parallax">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title center-align mb-40 text-left">
                                    <p>Need some help? Complete this form and we'll be in touch!</p>
                                    <div className="text-column col-lg-12 col-md-12 col-sm-12">
                                        <div className="inner-column">
                                            <br/><br/>
                                            <ul className="pr-ul">
                                                <a href="mailto:hello@nonprofitshq.com"><li className="text pt-10"><img src={emailSvgPro} style={{height: '25px', display: 'inline', marginRight: '15px'}} alt="emailIcon"/>hello@nonprofitshq.com</li></a>
                                                <a href='https://www.google.com/maps/dir/29.4365395,-98.4931754/nonprofitshq/@29.4314455,-98.499976,16z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x865c5f14f28aa02f:0x78cec167deec3a0!2m2!1d-98.4934688!2d29.4262673?entry=ttu' target='blank'><li className="text pt-10"><img src={locationPinSvg} style={{height: '25px', display: 'inline', marginRight: '15px'}} alt="locationIcon"/>110 E Houston St 3rd Floor, San Antonio, TX 78025</li></a>
                                            </ul>
                                        </div>
                                        <br/>
                                        <div className="footer-social  mt-30">                                    
                                            <a href="https://www.facebook.com/nonprofitshq"><i className="fab fa-facebook-f"></i></a>
                                            <a href="https://twitter.com/nonprofitshq"><i className="fab fa-twitter"></i></a>
                                            <a href="https://www.linkedin.com/company/nonprofitshq/"><i className="fab fa-linkedin"></i></a>
                                        </div>   
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="section-title center-align mb-6 text-left" style={{padding: '25px', borderRadius: '15px', border: '2px solid #f6f9fdff'}}>
                                    <HubspotForm 
                                        region="na1"
                                        portalId="45028631"
                                        formId="9332ac50-0ac4-4b6e-86e2-3129dd1475fe"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
            <Footer/>
    </Fragment>
    )
};
export default SupportPage;