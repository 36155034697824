import React, {Fragment} from 'react';
import Header from '../../components/header'
import Footer from '../../components/footer'
import './style.css';

import whiteSectionBackground from '../../images/bg/white-with-squares.png'
import boardGraphic from '../../images/bg/board.aa1f4947eca92fe44b39.jpg';
import boardGraphic2 from '../../images/gallery/rodeo-project-management-software-ONe-snuCaqQ-unsplash.jpg';
import pageTitleBackground from '../../images/titles/BoardPageTitle.png';
import CallToAction from '../../components/CallToAction';


const BoardPortalPage =() => {

    document.title = "Board Management | NonprofitsHQ"
    
    return(
        <Fragment>
            <Header/>
                <main>
                
                <section className="breadcrumb-area d-flex align-items-center parallax" style={{background: `url(${pageTitleBackground}) no-repeat`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-12">
                                <div className="breadcrumb-wrap text-left">
                                    <div className="breadcrumb-title mb-30">
                                        <h2>Board Portal</h2>
                                        <p style={{fontSize: '22px', color: '#fff', maxWidth: '800px'}}>Keep your Board of Directors on track with the powerful Board Portal from NonprofitsHQ.</p>
                                        <div className="breadcrumb-wrap">

                                    <nav aria-label="breadcrumb">

                                    </nav>
                                </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <div className="counter-area pt-60 pb-40 parallax">
                    <div className="container">
                        <div className="row wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-lg-6">
                                <div className="section-title center-align mb-40 text-left">
                                    <h2 className="secondary-heading">
                                        Manage your Board with NonprofitsHQ
                                    </h2>
                                    Elevate board collaboration, securely manage documents, and enhance decision-making with intuitive features tailored for your organization. Empower your board members to connect, contribute, and drive impactful change efficiently. Experience seamless governance that fuels your team. Request a demo today to learn how to revolutionize your Board effectiveness with NonprofitsHQ.
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="section-title center-align mb-6 text-left" style={{borderRadius: '5px', border: '10px solid #075272ff'}}>
                                    <img src={boardGraphic} alt=''/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className='blue-section d-flex justify-content-center pb-50'>
                    <div className="container mt-5">
                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-md-12 mb-4 text-center">
                                <h2>Ensure all meetings are impactful and productive:</h2>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                        <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Member Management</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Virtual Meetings</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Public Sessions</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Term Management</p>
                                    </div>
                                </div>
                            </div>                         

                        </div>
                    </div>
                </section>

                <section className='bootstrap-section pt-60 pb-40' style={{background: `url(${whiteSectionBackground}) no-repeat`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row justify-content-center mb-40 wow fadeInUp animated"  data-delay=".1s">
                            
                        </div>
                        <div className="row wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-md-5">
                                <div className="left-side">
                                    <div className="section-title center-align mb-6 text-left" style={{borderRadius: '5px', border: '10px solid #075272ff'}}>
                                        <img src={boardGraphic2} alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="right-side">
                                    <div className="section-title center-align mb-6 text-left" style={{background: 'rgba(255,255,255, .9)', padding: '5px', borderRadius: '15px'}}>
                                        <h2 className="secondary-heading">
                                            Empower your board of directors
                                        </h2>
                                        <p>
                                            Boards are an integral part of nonprofit organizations, providing strategic guidance, oversight, and support. 
                                            Effective board management ensures alignment with the mission, fosters accountability, and drives organizational success. 
                                            Let's empower our boards with the tools and resources they need to thrive.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <CallToAction />

                </main>
            <Footer/>
        </Fragment>
    )
};
export default BoardPortalPage;