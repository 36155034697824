import React, {Fragment} from 'react';
import Header from '../../components/header'
import Footer from '../../components/footer'
import './style.css';
import peopleGraphic from '../../images/bg/people.1662c7969fd63af31bb1.jpg';
import peopleGraphic2 from '../../images/gallery/brooke-cagle--uHVRvDr7pg-unsplash.jpg';
import whiteSectionBackground from '../../images/bg/white-with-squares.png'
import pageTitleBackground from '../../images/titles/PeoplePageTitle.png';
import CallToAction from '../../components/CallToAction';

const PeoplePage =() => {
    
    document.title = "Volunteer & Staff Management | NonprofitsHQ"
    
    return(
        <Fragment>
            <Header/>
                <main>
                <section className="breadcrumb-area d-flex align-items-center parallax" style={{background: `url(${pageTitleBackground}) no-repeat`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-12">
                                <div className="breadcrumb-wrap text-left">
                                    <div className="breadcrumb-title mb-30">
                                        <h2>People</h2>
                                        <p style={{fontSize: '22px', color: '#fff', maxWidth: '800px'}}>Organize your staff and volunteers from one place with NonprofitsHQ.</p>
                                        <div className="breadcrumb-wrap">

                                    <nav aria-label="breadcrumb">

                                    </nav>
                                </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <div className="counter-area pt-60 pb-40 parallax">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title center-align mb-40 text-left">
                                    <h2 className="secondary-heading">
                                        Onboard, screen and train with NonprofitsHQ
                                    </h2>
                                    <p>
                                        Streamline the onboarding process, execute comprehensive background screenings, provide top-tier training, and proficiently manage both volunteers and staff, securing a committed team to propel your organization towards success. Request a demo to learn how you can streamline your organization's people operations with NonprofitsHQ.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="section-title center-align mb-6 text-left" style={{borderRadius: '5px', border: '10px solid #075272ff'}}>
                                    <img src={peopleGraphic} alt=''/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className='blue-section d-flex justify-content-center pb-50'>
                    <div className="container mt-5">
                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-md-12 mb-4 text-center">
                                <h2>Unlock the full potential of your team with NonprofitsHQ:</h2>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                        <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Staff Management</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Volunteer Forms</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Background Checks</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Skills Assessments</p>
                                    </div>
                                </div>
                            </div>                         

                        </div>
                    </div>
                </section>

                <section className='bootstrap-section pt-60 pb-40' style={{background: `url(${whiteSectionBackground}) no-repeat`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-md-5">
                                <div className="left-side">
                                    <div className="section-title center-align mb-6 text-left" style={{borderRadius: '5px', border: '10px solid #075272ff'}}>
                                        <img src={peopleGraphic2} alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="right-side">
                                    <div className="section-title center-align mb-6 text-left" style={{background: 'rgba(255,255,255, .9)', padding: '5px', borderRadius: '15px'}}>
                                    <h2 className="secondary-heading">
                                        Amplify your outreach capabilities
                                    </h2>
                                        <p>
                                        Nonprofits often face challenges juggling volunteer and staff management across multiple platforms. 
                                        NonprofitsHQ offers a seamless solution by centralizing recruitment, contact, and screening processes in one platform.
                                        With NonprofitsHQ, organizations can effectively manage their workforce while focusing on their mission.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <CallToAction />

            </main>
        <Footer/>
    </Fragment>
    )
};
export default PeoplePage;