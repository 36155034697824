import { APIClient } from "./api_helper";

const api = new APIClient();


// Login Method
export const findLogin = (data: any) => api.create(`find_login`, data);

// Demo request
export const submitDemoRequest = (data:any) => api.create('demos', data);

// Featured events
export const getFeaturedEvents = () => api.get('featured/events', null);