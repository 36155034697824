import React, {Fragment} from 'react';
import Header from '../../components/header'
import Footer from '../../components/footer'
import './style.css';

import pageTitleBackground from '../../images/titles/PolicyPageTitle.png';

const PrivacyPage =() => {

    document.title = "Privacy | NonprofitsHQ"
    
    return(
        <Fragment>
            <Header/>
                <main>

                <section className="breadcrumb-area d-flex align-items-center parallax" style={{background: `url(${pageTitleBackground}) no-repeat`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-10">
                                <div className="breadcrumb-wrap text-left">
                                    <div className="breadcrumb-title mb-30">
                                        <h2>Privacy Policy</h2>
                                        {/* <p style={{fontSize: '22px', color: '#fff', maxWidth: '800px'}}>NonprofitsHQ General Terms Of Service</p> */}
                                        <div className="breadcrumb-wrap">

                                    <nav aria-label="breadcrumb">

                                    </nav>
                                </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                {/* content container */}
                <div className='content-container'>
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            NonprofitsHQ IS COMMITTED TO <br></br>PROTECTING YOUR PRIVACY
                                        </h4>
                                        <p>
                                        This Privacy Policy describes how NonprofitsHQ, Inc, directly or through any of its affiliated companies including, without limitation “NonprofitsHQ”, “we” or “us” may collect and use your personal information, and the choices you have concerning our use of such information.
                                        </p>
                                        <p>
                                        PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING OUR WEBSITES AND APPLICATIONS, (the “Sites”) OR OUR SOFTWARE PRODUCTS (the “Products”), OR BY SUBMITTING ANY INFORMATION OR MATERIALS TO OR THROUGH THE SITES OR OUR PRODUCTS, YOU AGREE TO THE COLLECTION AND USE OF SUCH INFORMATION OR MATERIALS BY NonprofitsHQ AND OTHERS AS DESCRIBED HEREIN. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THIS PRIVACY POLICY, OR TO THE COLLECTION AND USE OF INFORMATION OR MATERIALS FROM YOU BY NonprofitsHQ, DO NOT ACCESS OR USE THE SITES OR OUR PRODUCTS.
                                        </p>
                                        <p>
                                        If you are a user outside of the United States, you recognize and acknowledge that your personal information will be transferred out of your country and into the United States. You consent to such transfer through your use of the Sites or our Products. More information for residents of the EEA, UK and Switzerland is included below.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* VOLUNTARY DISCLOSURE OF PERSONAL INFORMATION */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            <strong>VOLUNTARY DISCLOSURE OF PERSONAL INFORMATION</strong>
                                        </h4>
                                        <p>Personal information is data that identifies, relates to, describes, is capable of associated with, or reasonably can be linked, directly or indirectly, with a particular individual or household.</p>
                                        <p>The Sites and our Products allow you to fill out forms for the purpose of learning more about NonprofitsHQ and the services we provide, applying for accounts, using our Products, or making general inquiries. You can always elect not to fill out these forms. By filling out these forms, however, you agree to send to us all of the information you input into those forms, including personal information in some cases. These forms may prompt you to fill in your:</p>
                                        <ul>
                                            <li>First and Last Name</li>
                                            <li>Email address</li>
                                            <li>Telephone number</li>
                                            <li>Title</li>
                                            <li>Company</li>
                                            <li>Reason for contacting us</li>
                                            <li>Payment information</li>
                                        </ul>
                                        <p>NonprofitsHQ may associate the data you voluntarily disclose to us with the data we collect when you visit the Sites or use our Products, as well as with data obtained from outside sources. By voluntarily disclosing such information to us, you expressly permit this use of your personal information by NonprofitsHQ. You also should be aware that if you include information such as your name in a comment on the Sites, such information becomes public information, meaning that you lose any privacy rights you might have with regard to that information. Such disclosures also may increase your chances of receiving unwanted communications from third parties.</p>
                                        <p>The Sites and our Products also link to NonprofitsHQ’s pages on various social networking sites, such Facebook, Twitter, and LinkedIn. If you become a fan, follow us, or otherwise interact with NonprofitsHQ on these social networking sites, then we may be able to personally identify you because we may be able to see whatever information you make publicly available about yourself on that social networking site. By doing so, you expressly permit this use of your personal information by NonprofitsHQ.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* INFORMATION WE MAY COLLECT AUTOMATICALLY */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            <strong>INFORMATION WE MAY COLLECT AUTOMATICALLY</strong>
                                        </h4>
                                        <p>We also may use, collect, track, and store certain data automatically when you visit the Site or use our Products, including:</p>
                                        <ul>
                                            <li>Your IP address;</li>
                                            <li>The website that referred you to the Sites;</li>
                                            <li>The search terms you used to find the Sites;</li>
                                            <li>Your device information, such as operating system, browser type, and device type, used to access the Sites;</li>
                                            <li>Session information, including how you use the Sites; and</li>
                                            <li>Cookies and other technologies, such as pixel tags, described in more detail below, see “Cookies and Online Advertising.”</li>
                                        </ul>
                                        <p>We might use this information, for example, to better understand customer behavior, to improve our products, services and advertising, or to provide our customers with more useful information about our Sites or Products.</p>
                                        <p>We might aggregate the information we collect in order to make use of it; aggregated data is considered non-personal information for purposes of this Privacy Policy. If we combine non-personal information with personal information, the combined information will be treated as personal information for as long as it remains combined.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* APIs AND HOW CAN MY INFORMATION BE SHARED */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            <strong>APIs AND HOW CAN MY INFORMATION BE SHARED</strong>
                                        </h4>
                                        <p>Some of our Products make use of an application programming interface (an “API”). An API allows our customer to automatically retrieve information from our Sites, for use/display elsewhere (e.g. on a nonprofit’s website). To give an example, a nonprofit might use our API to retrieve the names and photos of the top five (5) fundraisers for a campaign it is running; the nonprofit could then display that information on a leader board on its own website. If you are working with a nonprofit or other company using our API, information such as your name, picture, amount raised, and goal, may be retrieved by our customer through our API. If you create a team fundraising page, then your name, the team goal, and the amount raised by the team can also be retrieved by the nonprofit through our API. If you host an event for a charity, then your name, contact info, and the location of your event can be retrieved by the benefitting nonprofit through our API. These examples are for illustrative purposes only. The nonprofit or other customers of NonprofitsHQ that you engage with has access to other information.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* USE AND SHARING OF PERSONAL INFORMATION */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            <strong>USE AND SHARING OF PERSONAL INFORMATION</strong>
                                        </h4>
                                        <p>NonprofitsHQ and its affiliated entities may use the personal information you submit, consistent with this Privacy Policy. Our uses may include in order:</p>
                                        <ul>
                                            <li>To fulfill your requests to access, purchase and use our Products, including providing you with receipts, and to provide you with the services you request from us, including by others involved in fulfillment.</li>
                                            <li>To contact you for customer satisfaction surveys, market research or in connection with certain transactions.</li>
                                            <li>To provide you with notices such as communications about purchases and changes to our terms, conditions and policies.</li>
                                            <li>To communicate with you—via telephone, email, SMS or other means—as may be required in the context of our business relationship related to your access, purchase or use of our Products or related services.</li>
                                            <li>For marketing purposes.</li>
                                            <li>For internal purposes such as auditing, analysis (e.g., clickstream data) to improve our Sites or Products or our advertising.</li>
                                            <li>To develop our business relationship, if you represent a NonprofitsHQ Business Partner or Vendor.</li>
                                        </ul>
                                        <p>NonprofitsHQ does not sell or rent any of the personal data we collect or maintain. NonprofitsHQ does not maintain or use any payment information beyond the immediate need to process a transaction.</p>
                                        <p>In the course of meeting our customers’ needs and in improving our products and services, we may share certain data, including personal information, with service providers, vendors, marketing agencies and services, data management and analytics services, affiliates, and contract workers. If you tell us that you do not want your personal information used for further marketing contact, please contact us at privacy@nonprofitshq.com and we will respect your wishes.</p>
                                        <p>Additionally, we may store certain data, including personal information, on servers located at third-party data centers. In addition, we may obtain data, including personal information, from outside sources.</p>
                                        <p>NonprofitsHQ may disclose your personal information in connection with a business transaction, such as a sale of all or a portion of our business or assets. In that case, your information may be shared with the prospective purchaser or investor and transferred as part of the transaction.</p>
                                        <p>Other than as described above, NonprofitsHQ discloses your personal information only when permitted to do so by law, or when NonprofitsHQ believes in good faith that disclosure is reasonably necessary to protect the property, rights or safety of NonprofitsHQ, third parties or the public at large. This may include providing or using personal information to comply with any valid legal process such as a search warrant, subpoena, statute, or court order or to assist in any legal investigation or other proceeding when we deem necessary or appropriate.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* COOKIES AND ONLINE ADVERTISING */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            <strong>COOKIES AND ONLINE ADVERTISING</strong>
                                        </h4>
                                        <p>As true of most websites, we use cookies or similar tracking technology to collect information about your use of the Sites, which may be used to provide you with advertising or other services that are tailored to you and your interests. Most web browsers automatically accept cookies, and you can generally adjust your browser settings in order to delete or block certain cookies. To learn more about your choices regarding targeted advertising, please visit: <a href="http://www.networkadvertising.org/choices">http://www.networkadvertising.org/choices</a></p>
                                        <p>One type of advertising we may do involves showing advertisements to you on other sites after you have visited the Sites. This is sometimes known as “remarketing” because we may re-market our Products or services to you on other sites after you leave the Sites. We use third-party vendors, including Google, to show you these advertisements on various sites on the Internet. These third-party vendors, including Google, use cookies, pixel tags, and other technologies to serve you ads based on your prior visits to the Sites. To the extent that NonprofitsHQ may utilize third party vendors to assist NonprofitsHQ in its own analytical activities, the third parties are requested to comply with the privacy policies of NonprofitsHQ.</p>
                                        <p>Users may opt out of Google’s use of cookies by visiting the Google advertising opt-out page at <a href="http://www.google.com/privacy_ads.html">http://www.google.com/privacy_ads.html</a>. The Sites may use the Teracent cookie for remarketing, which you may opt out of by visiting the Teracent opt-out page at <a href="http://www.teracent.com/about-us/opt-out">http://www.teracent.com/about-us/opt-out</a>. The Sites may also use Google’s DoubleClick remarketing pixel, which you may opt out of by visiting the DoubleClick opt-out page at <a href="http://www.google.com/settings/ads/onweb#display_optout">http://www.google.com/settings/ads/onweb#display_optout</a> or the Network Advertising Initiative opt-out page at <a href="http://www.networkadvertising.org/managing/opt_out.asp">http://www.networkadvertising.org/managing/opt_out.asp</a>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* DATA RETENTION */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            <strong>DATA RETENTION</strong>
                                        </h4>
                                        <p>We retain your personal data relating to the use of our products and services for the duration of the customer relationship and for seven years after the customer account is closed for legal, regulatory, audit and tax requirements, subject to our legal requirements to keep your personal information for a longer period. After this period has expired, the personal information relating to your account and your use of the services will be deleted. Any personal information which you have provided to us for marketing purposes will be kept for these purposes until you notify us that you no longer wish to receive these communications.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECURITY */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            <strong>SECURITY</strong>
                                        </h4>
                                        <p>NonprofitsHQ takes very seriously the security of the information we collect and maintain. To prevent unauthorized access or disclosures, we utilize physical, electronic, and procedural safeguards to protect all of the information we collect and maintain, including any personal information you voluntarily provide. Please be aware that, although we endeavor to provide appropriate security for the information we collect and maintain, no security system can prevent all potential security breaches.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* CHILDREN */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            <strong>CHILDREN</strong>
                                        </h4>
                                        
                                        <p>The Sites are not directed to children under the age of 13 and we do not knowingly solicit data from or market to children under the age of 13. The use of the Sites is conditioned, in part, on a certification that the user is at least 13 years of age. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us at privacy@nonprofitshq.com. We will delete such data from our files upon request.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* CHOICE AND OPT-OUT */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            <strong>CHOICE AND OPT-OUT</strong>
                                        </h4>
                                        <p>We only send permission-based email for marketing purposes. In addition, users always have the opportunity to opt-out or change preferences via a link in the footer of all non-transactional email messages. These options are made available when you sign-up for our email lists and in email messages delivered from our company. Alternatively, you may email us at marketing@nonprofitshq.com. If you believe that you have received an unsolicited commercial email from NonprofitsHQ Systems on behalf of any of our customers, you may report it to us at marketing@nonprofitshq.com.</p>
                                        <p>Some communications (e.g. important account notifications and billing information) are considered transactional and are necessary for all customers of our Products. Customers may not opt out of these communications.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* REVIEWING AND REQUESTING CHANGES TO PERSONAL INFORMATION */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            <strong>REVIEWING AND REQUESTING CHANGES TO PERSONAL INFORMATION</strong>
                                        </h4>
                                        
                                        <p>You may review and request changes to the personal information we maintain about you by contacting us via email at <a href="mailto:privacy@nonprofitshq.com">privacy@nonprofitshq.com</a>. Please note that some information may remain in our records after your request is submitted.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* LINKS TO THIRD PARTY SITES */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            <strong>LINKS TO THIRD PARTY SITES</strong>
                                        </h4>
                                        <p>This Privacy Policy only applies to information collected by NonprofitsHQ. This Privacy Policy does not apply to the practices of companies or other parties that NonprofitsHQ does not own or control, or their employees that NonprofitsHQ does not manage. The Sites and our Products may contain links to third party websites. Any information you provide to, or that is collected by, third-party sites may be subject to the privacy policies of those sites, if any. We encourage you to read such privacy policies of any third-party sites you visit. It is the sole responsibility of such third parties to adhere to any applicable restrictions on the disclosure of your personal information, and by using the Sites or our Products you agree that NonprofitsHQ and its affiliates shall not be liable for the wrongful use or disclosure of your personal information by any third party.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* CROSS-BORDER TRANSFER OF PERSONAL INFORMATION */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                        <strong>CROSS-BORDER TRANSFER OF PERSONAL INFORMATION</strong>
                                        </h4>
                                        <p>The Sites are operated in the United States. Therefore, your personal information may be transferred to and stored in the United States or other countries with data protection laws that differ from the country where you are located. Your personal information may be available to government authorities under lawful orders and laws applicable in such foreign jurisdictions. As noted below, NonprofitsHQ complies with the GDPR, where applicable.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Your European Privacy Rights (EEA, SWISS & UK RESIDENTS) */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align mb-20">
                                            <strong>Your European Privacy Rights (EEA, SWISS & UK RESIDENTS)</strong>
                                        </h4>
                                        <p>Residents of the European Economic Area, Switzerland and the UK who purchase our products and/or use our services or otherwise who are resident in these territories and whose personal information is used by us for commercial purposes have certain rights under the General Data Protection Regulation or GDPR.</p>
                                        <ul>
                                            <li>Ensure your personal information is accurate and also we request that you let us know if your personal information changes.</li>
                                            <li>Request that we delete your personal information (although we may still need to retain your personal information as described above in this Privacy Policy for contractual and/or legal purposes).</li>
                                            <li>Request that we restrict the processing of your personal information (although we will still need to process your personal data during the duration of the contract and beyond for legal purposes).</li>
                                            <li>Access a copy of your personal information (and this is limited to information that is your personal information only, not to the underlying document or remaining information and subject to the exemptions and qualifications under the GDPR).</li>
                                            <li>Raise a complaint on how we have handled your personal information, you can contact us and we will investigate the matter and respond to you promptly. If you are not satisfied with our response, or if you prefer not to engage with us first, you can complain to any applicable data privacy authority in your country of residence.</li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Your California Privacy Rights (California Residents) */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            <strong>Your California Privacy Rights (California Residents)</strong>
                                        </h4>
                                        <p>Effective January 2020</p>
                                        <p>California residents have the right to request once per calendar year a list of the companies with which we may share personal information for the third parties’ direct marketing purposes and a list of the categories of such personal information shared. California residents may make this request by e-mailing privacy@nonprofitshq. Please include “California Privacy Rights Request” in the first line of the description and your California mailing or street address.</p>
                                        <p>If you are a California resident, you also have the right to access, or to request that we delete, any Personal Information we have collected about you, and inquire about our collection, sale and disclosure of your Personal Information by calling us at 888-860-6366 or by visiting <a href="https://support.nonprofitshq.com/">https://support.nonprofitshq.com/</a></p>
                                        <p>California residents have the right to opt-out of the sale of their Personal Information where applicable. However, as described in this Privacy Policy, NonprofitsHQ does not sell Personal Information.</p>
                                        <p>Your exercise of these rights will have no adverse effect on the price and quality of our services.</p>
                                        <p>California residents may request further information about compliance with California law and to make a request under California law by e-mailing privacy@nonprofitshq.com Please include “California Privacy Rights Request” in the first line of the description and your California mailing or street address.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* UPDATES TO PRIVACY POLICY */}
                    <div className="counter-area pt-60 pb-20 center-align">
                        <div className="container center-align">
                            <div className="row wow fadeInUp animated center-align" data-delay=".1s" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    <div className="section-title mb-40 text-left">
                                        <h4 className="secondary-heading center-align">
                                            <strong>UPDATES TO PRIVACY POLICY</strong>
                                        </h4>
                                        <p>We may update this Privacy Policy from time to time, and in our sole discretion. When we do so, you will see the changes on this page, and we will update the effective date of the Privacy Policy on the Sites. In all cases, NonprofitsHQ’s use of the information we collect is subject to the Privacy Policy in effect at the time such information is collected.</p>

                                        <strong>CONTACT US</strong>
                                        <p>If have any questions regarding our privacy practices, please contact us at <a href="mailto:privacy@nonprofitshq.com">privacy@nonprofitshq.com</a>.</p>

                                        <p>Updated on March 11, 2024.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
            <Footer/>
    </Fragment>
    )
};
export default PrivacyPage;