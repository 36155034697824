import React, {Fragment} from 'react';
import Header from '../../components/header'
import Footer from '../../components/footer'
import brokenGraphic from '../../images/graphics/404.png'


const ErrorPage =() => {

    document.title = "404 - Page Not Found | NonprofitsHQ";

    return(
        <Fragment>
            <Header/>
                <main>
                    <section className="d-flex align-items-center parallax" style={{background: '#075272ff', height: '100px'}}>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-xl-12 col-lg-12">
                                    </div>

                                </div>
                            </div>
                        </section>

                    <div className="counter-area mt-50 mb-50 parallax">
                        <div className="container d-flex justify-content-center">
                            <div className="row align-items-center ">
                                <div className="col-lg-6 text-left">
                                    <div className="section-title mb-0 primary-heading ">
                                        <h2>404 - Page Not Found</h2>
                                        <p style={{fontSize: '24px'}}>Whoops, looks like that link is not available right now. Return <a href='/home' style={{color: '#ef692aff'}}>home</a> or follow us on social media.</p>
                                        <div className="footer-social  mt-20 mb-10">                                    
                                            <a href="https://www.facebook.com/nonprofitshq"><i className="fab fa-facebook-f"></i></a>
                                            <a href="https://twitter.com/nonprofitshq"><i className="fab fa-twitter"></i></a>
                                            <a href="https://www.linkedin.com/company/nonprofitshq/"><i className="fab fa-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">
                                    <div className="section-title center-align mb-0 text-center">
                                        <img src={brokenGraphic} alt='brokenlinkgraphic' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </main>
            <Footer/>
        </Fragment>
    )
};
export default ErrorPage;

