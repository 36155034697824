import React, {Fragment} from 'react';
import Header from '../../components/header'
import Footer from '../../components/footer'


const SocialsPage =() => {

    document.title = "Connect with us on social media | NonprofitsHQ";

    return(
        <Fragment>
            <Header/>
                <main>
                    <section className="d-flex align-items-center parallax" style={{background: '#075272ff', height: '100px'}}>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-xl-12 col-lg-12">
                                    </div>

                                </div>
                            </div>
                        </section>

                    <div className="counter-area mt-50 mb-50 parallax">
                        <div className="container d-flex justify-content-center">
                            <div className="row align-items-center ">
                                <div className="col-lg-12 text-left">
                                    <div className="section-title mb-0 primary-heading ">
                                        <h2 style={{color: '#075272'}}>Follow us on Social Media</h2>
                                        <p style={{fontSize: '24px'}}>Follow us on social media for updates, tips, tricks and value adding content.</p>
                                        <div className="footer-social-other mt-30 mb-10">  
                                            <p style={{fontSize: '20px', fontWeight: '550'}}>Corporate Accounts</p>
                                            <a href="https://www.linkedin.com/company/nonprofitshq/" style={{background: '#0072b1'}}><i className="fab fa-linkedin"></i> LinkedIn</a>                                  
                                            <a href="https://www.facebook.com/nonprofitshq" style={{background: '#316FF6'}}><i className="fab fa-facebook-f"></i> Facebook</a>
                                            <a href="https://twitter.com/nonprofitshq" style={{background: '#1DA1F2'}}><i className="fab fa-twitter"></i> Twitter</a>
                                            <a href="https://www.youtube.com/@NonprofitsHQ" style={{background: '#CD201F'}}><i className="fab fa-youtube"></i> YouTube</a>
                                        </div>

                                        <div className="footer-social-other mt-60 mb-10">  
                                            <p style={{fontSize: '20px', fontWeight: '550'}}>Leadership Accounts</p>
                                            <a href="https://www.linkedin.com/in/zacjordanbrown/" style={{background: '#0072b1'}}><i className="fab fa-linkedin"></i> Zac Brown, CEO</a>                                  
                                            <a href="https://www.linkedin.com/in/alberto-carrazco-0518237b/" style={{background: '#0072b1'}}><i className="fab fa-linkedin"></i> Alberto Carrazco, COO</a>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </main>
            <Footer/>
        </Fragment>
    )
};
export default SocialsPage;

