import React from 'react';
import { Container } from "reactstrap";

import "./style.css";

const CalloutBox = (props:any) => {
  return (
    <section className='callout-box d-flex justify-content-center'>
      <Container>
        <div className=""  data-delay=".1s">
              <div className="card">
                <img src={props?.image} className="card-img-top" alt="Placeholder Image" />
                <div className="card-body">
                  <h5 className="card-title">{props?.title}</h5>
                  <p className="card-text">{props?.text}</p>
                </div>
            </div>
        </div>
      </Container>
    </section>
  );
};

export default CalloutBox;