import React from 'react'
import './style.css'

import logo from '../../images/logo/logo-whiteface.png';

const Footer = () =>{

  return(
    <footer className="footer-bg footer-p">
            <div className="footer-top  pt-70 pb-40">
                <div className="container">
                    <div className="row justify-content-between">

                        <div className="col-xl-4 col-lg-4 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title mb-30">
                                   <img src={logo} alt="NonprofitsHQ logo" />
                                </div>
                                <div className="footer-link">
                                    Unleash the power of your organization's initiatives with NonprofitsHQ's advanced tools, meticulously designed to amplify your impact without sacrificing user-friendly simplicity. Powerful tools that are easy to use - that's the NonprofitsHQ difference.
                                </div>
                                <div className="footer-social  mt-30">                                    
                                    <a href="https://www.facebook.com/nonprofitshq"><i className="fab fa-facebook-f"></i></a>
                                    <a href="https://twitter.com/nonprofitshq"><i className="fab fa-twitter"></i></a>
                                    <a href="https://www.linkedin.com/company/nonprofitshq/"><i className="fab fa-linkedin"></i></a>
                                </div>   
                            </div>
                        </div>
						<div className="col-xl-2 col-lg-2 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                    <h2>About Us</h2>
                                </div>
                                <div className="footer-link">
                                    <ul>                                        
                                        <li><a href="/login">Login</a></li>
                                        <li><a href="/company/ai">Responsible AI</a></li>
                                        <li><a href="/pricing">Pricing</a></li>
                                        <li><a href="/company/values">Our Values</a></li>
                                        <li><a href="/policy/tos">Terms of Service</a></li>
                                        <li><a href="/policy/privacy">Privacy Policy</a></li>
                                        <li><a href="/policy/sla_security">Sla Security</a></li>
                                        <li><a href="/demo" >Request a Demo</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                    <h2>Solutions</h2>
                                </div>
                                <div className="footer-link">
                                    <ul>
                                        <li><a href="../product/fundraising">Fundraising</a></li>
                                        <li><a href="../product/impact">Impact</a></li>
                                        <li><a href="../product/events">Events</a></li>
                                        <li><a href="../product/policy">Policy</a></li>
                                        <li><a href="../product/people">People</a></li>
                                        <li><a href="../product/board">Board Management</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>  
                        <div className="col-xl-3 col-lg-3 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                    <h2>Conect With Us</h2>
                                </div>
                                <div className="footer-link">
                                    <ul>
                                        <li>
                                            <a href="mailto:hello@nonprofitshq.com"><i className="fa fa-envelope" style={{fontSize: '15px'}}><br/></i> hello@nonprofitshq.com</a>
                                       </li>
                                        <li>
                                            <a href="https://www.google.com/maps/dir/29.4365395,-98.4931754/nonprofitshq/@29.4314455,-98.499976,16z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x865c5f14f28aa02f:0x78cec167deec3a0!2m2!1d-98.4934688!2d29.4262673?entry=ttu" target='blank'><i className="fa fa-map" style={{fontSize: '15px'}}><br/></i> 110 E Houston St, 3rd Floor, San Antonio TX 78205</a>
                                       </li>
                                    </ul>
                                </div>
                            </div>
                        </div>  
                        
                    </div>
                </div>
            </div>
            <div className="copyright-wrap">
                <div className="container">
                    <div className="row align-items-center text-center">
                        <div className="col-12">                         
                               Copyright  &copy; 2024 NonprofitsHQ. All rights reserved.                       
                        </div>
                        
                    </div>
                </div>
            </div>
        </footer>
  )
} 

export default Footer;