import React, {Fragment} from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import CalloutBox from '../../components/CalloutBox';
import './style.css';

import {
    Row,
    Col,
    Container,
    Card,
    CardBody
} from "reactstrap";

import pageTitleBackground from '../../images/titles/ValuesPageTitle.png';
import valueGraphic1 from '../../images/bg/DareToInnovate.png'
import valueGraphic2 from '../../images/bg/OriginalityShapesOurIdentity.png'
import valueGraphic3 from '../../images/bg/GrowRelentlesslyInAllWeDo.png'
import valueGraphic4 from '../../images/bg/OpenMindsForgeNewIdeas.png'
import valueGraphic5 from '../../images/bg/OpimizeForInfniteValue.png'
import valueGraphic6 from '../../images/bg/DeterminationFuelsOurJuorneyToSuccess.png'
import CallToAction from '../../components/CallToAction';

const ValuesPage =() => {

    document.title = "Company Values | NonprofitsHQ"

    return(
        <Fragment>
            <Header/>
                <main>
                
                    <section className="breadcrumb-area d-flex align-items-center parallax" style={{background: `url(${pageTitleBackground}) no-repeat`, backgroundSize: 'cover'}}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="breadcrumb-wrap text-left">
                                        <div className="breadcrumb-title mb-30">
                                            <h2>Values</h2>
                                            <p style={{fontSize: '22px', color: '#fff', maxWidth: '800px'}}>People should never have to adapt to technology. </p>
                                            <div className="breadcrumb-wrap">

                                        <nav aria-label="breadcrumb">

                                        </nav>
                                    </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <Container style={{marginBottom: '50px', marginTop: '0px'}}>      
                        <Row>
                            <Card style={{marginTop: '25px', marginBottom: '50px'}}>
                                <CardBody>
                                    <div className="section-title center-align mb-40 text-center" style={{padding: '15px'}}>
                                        <h2 className="secondary-heading pt-40" style={{marginTop: '25px', marginBottom: '25px'}}>
                                            Our Motto: "Do Good" Every Day
                                        </h2>
                                        <p>
                                        At NonprofitsHQ, we firmly believe that our core values are the compass guiding our every action and decision. They serve as the bedrock of our commitment to excellence, integrity, and responsible conduct in all facets of our operations. We hold ourselves accountable to the highest standards of these core values, ensuring that they resonate within every aspect of our work. These values drive us forward, shaping our culture, and reinforcing our dedication to serving our customers, employees, and society with unwavering commitment and ethical responsibility.
                                        </p>
                                    </div>
                                    </CardBody>
                            </Card>              
                        </Row>
                        <Row style={{marginBottom: '25px'}}>
                            <Col md={'4'} className='justify-content-center wow fadeInUp animated mb-25'>
                                <CalloutBox title="Dare to innovate and explore" text="We champion innovation, curiosity, and courage to challenge norms, fostering an environment for bold ideas and calculated risks. Learning from successes and setbacks fuels our growth. By daring to innovate, we drive transformative change, shaping a future of ingenuity and progress." image={valueGraphic1}/>
                            </Col>
                            <Col md={'4'} className='justify-content-center wow fadeInUp animated mb-25'>
                                <CalloutBox title="Originality shapes our identity" text="Originality shapes our identity, celebrating unique ideas and perspectives. We foster an environment where innovation thrives, unconventional thinking is encouraged, and diverse viewpoints converge. It's our cornerstone, inspiring us to challenge conventions, explore new horizons, and craft innovative solutions. By embracing originality, we honor diverse perspectives and create a lasting impact reflecting our unique identity." image={valueGraphic2}/>
                            </Col>
                            <Col md={'4'} className='justify-content-center wow fadeInUp animated mb-25'>
                                <CalloutBox title="Grow relentlessly in all we pursue" text="Our commitment to relentless growth fuels innovation, propels success, and exceeds expectations. We foster personal and professional development, empowering excellence in all we do. This drive is ingrained, propelling us forward as we embrace change, harness potential, and elevate achievement." image={valueGraphic3}/>
                            </Col>
                        </Row> 

                        <Row pb={'25'} pt={'500'} mt={'500'}>
                            <Col md={'4'} className='justify-content-center wow fadeInUp animated mb-25'>
                                <CalloutBox title="Optimize for infinite value" text="Our ethos is to create enduring, sustainable impact. It guides us to make decisions and take actions for lasting benefits, not just short-term gains. We pursue strategies and initiatives that ripple into the future, optimizing efforts, resources, and innovations. We think holistically, considering broader implications, aiming to leave a legacy of positive change that transcends generations." image={valueGraphic4}/>
                            </Col>
                            <Col md={'4'} className='justify-content-center wow fadeInUp animated mb-25'>
                                <CalloutBox title="Open minds forge new ideas" text="Open minds forge new ideas embodies our belief in the power of openness, diversity, and collaboration to spark innovation. We foster an inclusive environment welcoming diverse perspectives, encouraging everyone to share unique insights. By championing open-mindedness, we create fertile ground for innovation to thrive, cultivating a rich tapestry of ideas, pioneering new paths in our pursuit of excellence." image={valueGraphic5}/>
                            </Col>
                            <Col md={'4'} className='justify-content-center wow fadeInUp animated mb-25'>
                                <CalloutBox title="Determination Fuels Our Success" text="Our journey to success is fueled by determination, signifying resilience, persistence, and focus on overcoming obstacles. Success isn't just a destination but a journey shaped by our commitment to push boundaries, surmount challenges, and strive for excellence. This value underscores our dedication to staying the course, even in adversity. Embracing determination empowers us to navigate complexities, learn from setbacks, and drive progress toward our objectives." image={valueGraphic6}/>
                            </Col>
                        </Row> 
                    </Container>
                    
                    <CallToAction />

                </main>
            <Footer/>
        </Fragment>
    )
};
export default ValuesPage;