import React, {Fragment} from 'react';
import Header from '../../components/header'
import MeetAlberto from '../../images/alberto/alberto.jpg'
import Footer from '../../components/footer'

import pageTitleBackground from '../../images/titles/MeetPageTitle.png';

import CallToAction from '../../components/CallToAction';
import HubspotForm from '../../components/HubspotForm';


const meetAlberto =() => {

    document.title = "Meet Alberto | NonprofitsHQ"

    return(
        <Fragment>
            <Header/>
            <main>
                <section className="breadcrumb-area d-flex align-items-center parallax" style={{background: `url(${pageTitleBackground}) no-repeat`, backgroundSize: 'cover'}}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="breadcrumb-wrap text-left">
                                        <div className="breadcrumb-title mb-30">
                                            <h2>Meet Alberto</h2>
                                            <p style={{fontSize: '22px', color: '#fff', maxWidth: '800px'}}>Let's Make Nonprofit Management Easy</p>
                                            <div className="breadcrumb-wrap">

                                        <nav aria-label="breadcrumb">

                                        </nav>
                                    </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <div className="counter-area pt-60 pb-40 parallax">
                        <div className="container">
                            <div className="row">
                                <div className='col-lg-4'>
                                    
                                        <div className="section-title center-align mb-40 text-left">
                                            <div className="section-title center-align mb-6 text-left" style={{borderRadius: '10px', border: '5px solid #075272ff'}}>
                                                <img src={MeetAlberto} alt='meetalberto' />
                                            </div>
                                            
                                        </div>
                                    
                                </div>

                                <div className='col-lg-4'>
                                    
                                            <div className="section-title center-align mb-40 text-left">
                                                <p>
                                                Meet Alberto, the Co-Founder and COO of NonprofitsHQ, a visionary leader dedicated to empowering nonprofits worldwide. With a profound commitment to their success, Alberto drives innovation and fosters impactful change within the sector. His strategic guidance ensures that NonprofitsHQ provides essential tools and support for organizations to thrive and make a lasting difference in their communities. Join us in celebrating Alberto's unwavering dedication to advancing the missions of nonprofits everywhere.
                                                </p>
                                                <div className="footer-social  mt-30">                                    
                                                    <a href="mailto:alberto@nonprofitshq.com"><i className="fa fa-envelope"></i></a>
                                                    <a href="https://www.linkedin.com/in/alberto-carrazco-0518237b"><i className="fab fa-linkedin"></i></a>
                                                </div>
                                                
                                            </div>
                                    
                                </div>

                                <div className="col-lg-4">

                                        <div className="section-title center-align mb-6 text-left" style={{padding: '25px', borderRadius: '15px', border: '2px solid #f6f9fdff'}}>
                                            <h4 className='secondary-heading'>Request a Demo</h4>
                                            <HubspotForm
                                                region="na1"
                                                portalId="45028631"
                                                formId='90064b97-6ee2-4845-8ffe-867751f33ec1'
                                            />
                                        </div>

                                    
                                </div>

                                

                               
                            </div>
                        </div>
                    </div>

                    <CallToAction />

                </main>
                <Footer/>
        </Fragment>
    )
};
export default meetAlberto;
