import React, {Fragment} from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import pageTitleBackground from '../../images/titles/PricingPageTitle.png';
import chooseUsGraphic from '../../images/bg/brooke-cagle-g1Kr4Ozfoac-unsplash.jpg';
import './style.css';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const PricingPage =() => {

    document.title = "Pricing | NonprofitsHQ"
  
    return(
        <Fragment>
            <Header/>
                <main>

                    <section className="breadcrumb-area d-flex align-items-center" style={{background: `url(${pageTitleBackground}) no-repeat`, backgroundSize: 'cover'}}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="breadcrumb-wrap text-left">
                                        <div className="breadcrumb-title mb-30">
                                            <h2>Pricing</h2>
                                            <p style={{fontSize: '22px', color: '#fff', maxWidth: '800px'}}>Affordable pricing that makes NonprofitsHQ accessible to organizations of all sizes without breaking the bank.</p>
                                            <div className="breadcrumb-wrap">

                                        <nav aria-label="breadcrumb">

                                        </nav>
                                    </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section  className="product-area pt-50 pb-30 fix">
                        <a id='includes-section' className='anchor'/>
                        <div className="container">
                            <div className="row">
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="pricing-box pricing-box2 active text-center mb-60  wow fadeInUp  animated">
                                        <div className="pricing-head">
                                            <h1 style={{color: '#000'}}>
                                                All subscriptions include:
                                            </h1>
                                            <div className="price-count">
                                            </div>
                                        </div>
                                        <div className="pricing-body mt-30 mb-60 text-left d-none d-md-block">
                                            
                                            <Tabs>
                                                <TabList style={{borderBottom: '1 px solid #075272ff'}}>
                                                <Tab>People</Tab>
                                                <Tab>Fundraising</Tab>
                                                <Tab>Events</Tab>
                                                <Tab>Board</Tab>
                                                <Tab>Impact</Tab>
                                                <Tab>Marketing</Tab>
                                                <Tab>Customization</Tab>
                                                </TabList>

                                                <TabPanel>
                                                    <div className="container mt-5">
                                                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                                                            <div className="col-md-12 mb-4 text-center">
                                                                <h2>Unlock the full potential of your team with NonprofitsHQ:</h2>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center">
                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Integrated Background Checks*</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Skills Assessments</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Skills Based Matching</p>
                                                                    </div>
                                                                </div>
                                                            </div>                        

                                                        </div>

                                                        <div className="row justify-content-center">
                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Staff/Volunteer Assignments</p>
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">iOS & Android Staff Mobile App</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">iOS & Android Volunteer App</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                                    
                                                </TabPanel>

                                                <TabPanel>
                                                    <div className="container mt-5">

                                                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                                                            <div className="col-md-12 mb-4 text-center">
                                                                <h2>Optimize your organization's success through:</h2>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center">

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Online Donations</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">In-Person Donations</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Fundraising Campaigns</p>
                                                                    </div>
                                                                </div>
                                                            </div>  

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Automatic Receipts</p>
                                                                    </div>
                                                                </div>
                                                            </div>                       

                                                        </div>

                                                        <div className="row justify-content-center">
                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Hosted Donation Pages</p>
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Grant Management & Tracking</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Grant Eligibility Check</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Donor Profiles</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                
                                                </TabPanel>

                                                <TabPanel>
                                                    <div className="container mt-5">
                                                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                                                            <div className="col-md-12 mb-4 text-center">
                                                                <h2>Seamlessly create and manage memorable events through:</h2>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center">
                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Free & Paid Events</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Attendee Management</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Online & In Person Ticket Sales</p>
                                                                    </div>
                                                                </div>
                                                            </div>  

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Recurring Donations</p>
                                                                    </div>
                                                                </div>
                                                            </div>                       

                                                        </div>

                                                        <div className="row justify-content-center">
                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Hosted Event Pages</p>
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Event Support Center</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Promo Codes & Presale</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Event Statistics & Reporting</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </TabPanel>

                                                <TabPanel>
                                                    <div className="container mt-5">
                                                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                                                            <div className="col-md-12 mb-4 text-center">
                                                                <h2>Ensure all meetings are impactful and productive:</h2>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center">
                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Director Management</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Term Management</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Hosted Virtual Meetings</p>
                                                                    </div>
                                                                </div>
                                                            </div>                      

                                                        </div>

                                                        <div className="row justify-content-center">

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Smart Agenda</p>
                                                                    </div>
                                                                </div>
                                                            </div>   

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Automatic Minutes</p>
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Automated Notices</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Member Recruitment</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        
                                                    </div>
                                                </TabPanel>

                                                <TabPanel>

                                                    <div className="container mt-5">

                                                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                                                            <div className="col-md-12 mb-4 text-center">
                                                                <h2>Discover the power of your impact through:</h2>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center">
                                                            
                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Cost Tracking</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Contact Management</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Program Offerings</p>
                                                                    </div>
                                                                </div>
                                                            </div>  

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Automated Impact Reports</p>
                                                                    </div>
                                                                </div>
                                                            </div>                       

                                                        </div>

                                                        <div className="row justify-content-center">
                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">iOS & Android Kiosk</p>
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                        </div>
                                                    </div>
                                                    
                                                </TabPanel>

                                                <TabPanel>
                                                    
                                                    <div className="container mt-5">

                                                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                                                            <div className="col-md-12 mb-4 text-center">
                                                                <h2>Amplify your organization's marketing through:</h2>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center">

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Visual Editor</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Hosted Subscription Form</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        
                                                    </div>

                                                </TabPanel>

                                                <TabPanel>
                                                    <div className="container mt-5">

                                                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                                                            <div className="col-md-12 mb-4 text-center">
                                                                <h2>Strengthen your organization's brand through:</h2>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center">

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Custom Subdomain</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                </TabPanel>
                                            </Tabs> 
                                                {/* <ul style={{display: 'grid', gridTemplateColumns: 'auto auto auto auto'}}>
                                                    <li>- Integrated Background Checks*</li>
                                                    <li>- Skills Assessments</li>
                                                    <li>- Skills Based Matching</li>
                                                    <li>- Staff/Volunteer Assignments</li>
                                                    <li>- iOS & Android Staff Mobile App</li>
                                                    <li>- iOS & Android Volunteer App</li>
                                                    <li>- Online Donations</li>
                                                    <li>- In-Person Donations</li>
                                                    <li>- Fundraising Campaigns </li>
                                                    <li>- Automatic Receipts</li>
                                                    <li>- Hosted Donation Pages</li>
                                                    <li>- Grant Management & Tracking</li>
                                                    <li>- Grant Eligibility Check</li>
                                                    <li>- Donor Profiles</li>
                                                    <li>- Free & Paid Events</li>
                                                    <li>- Attendee Management</li>
                                                    <li>- Online & In Person Ticket Sales</li>
                                                    <li>- Recurring Donations</li>
                                                    <li>- Hosted Event Pages</li>
                                                    <li>- Event Support Center</li>
                                                    <li>- Promo Codes & Presale</li>
                                                    <li>- Event Statistics & Reporting</li>
                                                    <li>- Director Management</li>
                                                    <li>- Term Management</li>
                                                    <li>- Hosted Virtual Meetings</li>
                                                    <li>- Smart Agenda</li>
                                                    <li>- Automatic Minutes</li>
                                                    <li>- Automated Notices</li>
                                                    <li>- Resolution Records</li>
                                                    <li>- Member Recruitment</li>
                                                    <li>- Snail Mail Notices</li>
                                                    <li>- iOS & Android App</li>
                                                    <li>- Cost Tracking</li>
                                                    <li>- Contact Management</li>
                                                    <li>- Program Offerings</li>
                                                    <li>- Automated Impact Reports</li>
                                                    <li>- iOS & Android Kiosk</li>
                                                    <li>- Automated Impact Reports</li>
                                                    <li>- Visual Editor</li>
                                                    <li>- Hosted Subscription Form</li>
                                                    <li>- Custom Subdomain</li>
                                                </ul> */}

                                        </div>
                                        <div className="pricing-body mt-30 mb-60 text-left d-md-none">

                                        <Tabs>
                                                <TabList style={{borderBottom: '1 px solid #075272ff', overflow: 'scroll'}}>
                                                <Tab>People</Tab>
                                                <Tab>Fundraising</Tab>
                                                <Tab>Events</Tab>
                                                <Tab>Board</Tab>
                                                <Tab>Impact</Tab>
                                                <Tab>Marketing</Tab>
                                                <Tab>Customization</Tab>
                                                </TabList>

                                                <TabPanel>
                                                    <div className="container mt-5">
                                                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                                                            <div className="col-md-12 mb-4 text-center">
                                                                <h2>Unlock the full potential of your team with NonprofitsHQ:</h2>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center">
                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Integrated Background Checks*</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Skills Assessments</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Skills Based Matching</p>
                                                                    </div>
                                                                </div>
                                                            </div>                        

                                                        </div>

                                                        <div className="row justify-content-center">
                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Staff/Volunteer Assignments</p>
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">iOS & Android Staff Mobile App</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">iOS & Android Volunteer App</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                                    
                                                </TabPanel>

                                                <TabPanel>
                                                    <div className="container mt-5">

                                                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                                                            <div className="col-md-12 mb-4 text-center">
                                                                <h2>Optimize your organization's success through:</h2>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center">

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Online Donations</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">In-Person Donations</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Fundraising Campaigns</p>
                                                                    </div>
                                                                </div>
                                                            </div>  

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Automatic Receipts</p>
                                                                    </div>
                                                                </div>
                                                            </div>                       

                                                        </div>

                                                        <div className="row justify-content-center">
                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Hosted Donation Pages</p>
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Grant Management & Tracking</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Grant Eligibility Check</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Donor Profiles</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                
                                                </TabPanel>

                                                <TabPanel>
                                                    <div className="container mt-5">
                                                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                                                            <div className="col-md-12 mb-4 text-center">
                                                                <h2>Seamlessly create and manage memorable events through:</h2>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center">
                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Free & Paid Events</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Attendee Management</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Online & In Person Ticket Sales</p>
                                                                    </div>
                                                                </div>
                                                            </div>  

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Recurring Donations</p>
                                                                    </div>
                                                                </div>
                                                            </div>                       

                                                        </div>

                                                        <div className="row justify-content-center">
                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Hosted Event Pages</p>
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Event Support Center</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Promo Codes & Presale</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Event Statistics & Reporting</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </TabPanel>

                                                <TabPanel>
                                                    <div className="container mt-5">
                                                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                                                            <div className="col-md-12 mb-4 text-center">
                                                                <h2>Ensure all meetings are impactful and productive:</h2>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center">
                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Director Management</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Term Management</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Hosted Virtual Meetings</p>
                                                                    </div>
                                                                </div>
                                                            </div>                     

                                                        </div>

                                                        <div className="row justify-content-center">

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Smart Agenda</p>
                                                                    </div>
                                                                </div>
                                                            </div>   

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Automatic Minutes</p>
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Automated Notices</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Member Recruitment</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        
                                                    </div>
                                                </TabPanel>

                                                <TabPanel>

                                                    <div className="container mt-5">

                                                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                                                            <div className="col-md-12 mb-4 text-center">
                                                                <h2>Discover the power of your impact through:</h2>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center">
                                                            
                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Cost Tracking</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Contact Management</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Program Offerings</p>
                                                                    </div>
                                                                </div>
                                                            </div>  

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Automated Impact Reports</p>
                                                                    </div>
                                                                </div>
                                                            </div>                       

                                                        </div>

                                                        <div className="row justify-content-center">
                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">iOS & Android Kiosk</p>
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                        </div>
                                                    </div>
                                                    
                                                </TabPanel>

                                                <TabPanel>
                                                    
                                                    <div className="container mt-5">

                                                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                                                            <div className="col-md-12 mb-4 text-center">
                                                                <h2>Amplify your organization's marketing through:</h2>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center">

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Visual Editor</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Hosted Subscription Form</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        
                                                    </div>

                                                </TabPanel>

                                                <TabPanel>
                                                    <div className="container mt-5">

                                                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                                                            <div className="col-md-12 mb-4 text-center">
                                                                <h2>Strengthen your organization's brand through:</h2>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center">

                                                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                                                <div className="card d-flex align-items-center">
                                                                    <div className="card-body text-center d-flex align-items-center card-100">
                                                                        <i className="fa fa-check mr-3 icon"></i>
                                                                        <p className="mb-0">Custom Subdomain</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                </TabPanel>
                                            </Tabs>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                    <section  className="product-area pt-100 pb-30 fix">
                        <a id='subscritions-section' className='anchor'/>
                        <div className="container">
                            <div className="row">
                            
                                <div className="col-lg-12">
                                    <div className="section-title center-align mb-50 text-center">
                                        <h2>
                                            Affordable Solutions
                                        </h2>
                                    </div>
                                    <div id="sticky-pricing-buttons">
                                
                                        <div id="sticky-buttons" className="sticky-buttons d-flex justify-content-left align-items-left d-none d-md-flex">
                                            <a href='#includes-section' className="btn ss-btn-pricing">What's Included</a>
                                            <a href='#subscritions-section' className="btn ss-btn-pricing">Plans</a>
                                            <a href='#why-us-section' className="btn ss-btn-pricing">Why Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-none d-sm-flex">
                                
                                <div className="col-lg-4 col-md-12 ">
                                    <div className="pricing-box pricing-box2 text-center mb-60  wow fadeInLeft  animated" style={{minHeight: '1085px'}}>
                                            <div className="pricing-head">
                                                <h3>Essentials</h3>
                                            <div className="price-count">
                                                    <h2><sub>$</sub>499 <strong>/ Monthly</strong></h2>
                                                </div>
                                            </div>
                                            <div className="pricing-btn">
                                            <a href="/demo" className="btn ss-btn" style={{zIndex: '2'}}>Request a Demo</a>
                                            </div>
                                            <div className="pricing-body mt-30 mb-60 text-left">
                                            <ul>
                                                    <li>10 Staff Users</li>
                                                    <li>100 Volunteer Profiles</li>
                                                    <li>1 Custom Onboarding Flow</li>
                                                    <li>1 Hosted Volunteer Application</li>
                                                    <li>10 AI Written Grants</li>
                                                    <li>5 Fundraising Events</li>
                                                    <li>2 Ticketing Tiers</li>
                                                    <li>10 Public Board of Director Sessions</li>
                                                    <li>5 Program Management</li>
                                                    <li>5 Newsletters</li>
                                                </ul>
                                            </div>



                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12" id='pricing-box2'>
                                    <div className="pricing-box pricing-box2 active text-center mb-60  wow fadeInUp  animated" style={{minHeight: '1085px'}}>
                                            <div className="pricing-head">
                                                <h3>Impact</h3>
                                            <div className="price-count">
                                                    <h2><sub>$</sub>999 <strong>/ Monthly</strong></h2>
                                                </div>
                                            </div>
                                            <div className="pricing-btn">
                                            <a href="/demo" className="btn ss-btn" style={{zIndex: '2'}}>Request a Demo</a>
                                            </div>
                                            <div className="pricing-body mt-30 mb-60 text-left">
                                            <ul>
                                                    <li>50 Staff Users</li>
                                                    <li>200 Volunteer Profiles</li>
                                                    <li>10 Custom Onboarding Flows</li>
                                                    <li>10 Hosted Volunteer Applications</li>
                                                    <li>50 AI Written Grants</li>
                                                    <li>20 Fundraising Events</li>
                                                    <li>5 Ticketing Tiers</li>
                                                    <li>50 Public Board of Director Sessions</li>
                                                    <li>20 Program Management</li>
                                                    <li>10 Newsletters</li>
                                                </ul>
                                            </div>



                                        </div>
                                    </div>
                                <div className="col-lg-4 col-md-12" id='pricing-box3'>
                                    <div className="pricing-box pricing-box2 text-center mb-60  wow fadeInRight  animated" style={{minHeight: '1085px'}}>
                                            <div className="pricing-head">
                                                <h3>Amplify</h3>
                                            <div className="price-count">
                                                    <h2><strong>Custom</strong></h2>
                                                </div>
                                            </div>
                                            <div className="pricing-btn">
                                            <a href="/demo" className="btn ss-btn" style={{zIndex: '2'}}>Request a Demo</a>
                                            </div>
                                            <div className="pricing-body mt-30 mb-60 text-left">
                                            <ul>
                                                <li>Unlimited Staff Users</li>
                                                <li>Unlimited Volunteer Profiles</li>
                                                <li>Unlimited Custom Onboarding Flows</li>
                                                <li>Unlimited Hosted Volunteer Applications</li>
                                                <li>Unlimited Ai Written Grants</li>
                                                <li>Unlimited Fundraising Events</li>
                                                <li>Unlimited Ticketing Tiers</li>
                                                <li>Unlimited Public Board of Director Sessions</li>
                                                <li>Unlimited Program Management</li>
                                                <li>Embedded Donation Widget</li>
                                                <li>Unlimited Newsletters</li>
                                                <li>Donor Wealth Profiles</li>
                                                <li>White Labeling</li>
                                                <li>Custmized Verbiage</li>
                                                <li>Custom Domains</li>
                                                <li>Custom Emails</li>
                                            </ul>
                                            </div>



                                        </div>
                                    </div>
                            </div>

                            <div id="carouselExampleIndicators" className="carousel slide d-lg-none d-md-none" data-ride="carousel">
                            <ol className="carousel-indicators" style={{marginBottom: '74rem'}}>
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="indicator active"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1" className='indicator'></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="2" className='indicator'></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="col-lg-4 col-md-12">
                                        <div className="pricing-box pricing-box2 text-center mb-60  wow fadeInLeft  animated" style={{minHeight: '1085px', width: '350px'}}>
                                                <div className="pricing-head">
                                                    <h3>Essentials</h3>
                                                <div className="price-count">
                                                        <h2><sub>$</sub>499 <strong>/ Monthly</strong></h2>
                                                    </div>
                                                </div>
                                                <div className="pricing-btn">
                                                <a href="/demo" className="btn ss-btn" style={{zIndex: '2'}}>Request a Demo</a>
                                                </div>
                                                <div className="pricing-body mt-30 mb-60 text-left">
                                                <ul>
                                                        <li>10 Staff Users</li>
                                                        <li>100 Volunteer Profiles</li>
                                                        <li>1 Custom Onboarding Flow</li>
                                                        <li>1 Hosted Volunteer Application</li>
                                                        <li>10 AI Written Grants</li>
                                                        <li>5 Fundraising Events</li>
                                                        <li>2 Ticketing Tiers</li>
                                                        <li>10 Public Board of Director Sessions</li>
                                                        <li>5 Program Management</li>
                                                        <li>5 Newsletters</li>
                                                    </ul>
                                                </div>



                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="col-lg-4 col-md-12" >
                                        <div className="pricing-box pricing-box2 active text-center mb-60  wow fadeInUp  animated" style={{minHeight: '1085px', width: '350px'}}>
                                                <div className="pricing-head">
                                                    <h3>Impact</h3>
                                                <div className="price-count">
                                                        <h2><sub>$</sub>999 <strong>/ Monthly</strong></h2>
                                                    </div>
                                                </div>
                                                <div className="pricing-btn">
                                                <a href="/demo" className="btn ss-btn" style={{zIndex: '2'}}>Request a Demo</a>
                                                </div>
                                                <div className="pricing-body mt-30 mb-60 text-left">
                                                <ul>
                                                        <li>50 Staff Users</li>
                                                        <li>200 Volunteer Profiles</li>
                                                        <li>10 Custom Onboarding Flows</li>
                                                        <li>10 Hosted Volunteer Applications</li>
                                                        <li>50 AI Written Grants</li>
                                                        <li>20 Fundraising Events</li>
                                                        <li>5 Ticketing Tiers</li>
                                                        <li>50 Public Board of Director Sessions</li>
                                                        <li>20 Program Management</li>
                                                        <li>10 Newsletters</li>
                                                    </ul>
                                                </div>



                                            </div>
                                        </div>
                                </div>
                                <div className="carousel-item ">
                                    <div className="col-lg-4 col-md-12">
                                            <div className="pricing-box pricing-box2 text-center mb-60  wow fadeInRight  animated" style={{minHeight: '1085px', width: '350px'}}>
                                                    <div className="pricing-head">
                                                        <h3>Amplify</h3>
                                                    <div className="price-count">
                                                            <h2><strong>Custom</strong></h2>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-btn">
                                                    <a href="/demo" className="btn ss-btn" style={{zIndex: '2'}}>Request a Demo</a>
                                                    </div>
                                                    <div className="pricing-body mt-30 mb-60 text-left">
                                                    <ul>
                                                        <li>Unlimited Staff Users</li>
                                                        <li>Unlimited Volunteer Profiles</li>
                                                        <li>Unlimited Custom Onboarding Flows</li>
                                                        <li>Unlimited Hosted Volunteer Applications</li>
                                                        <li>Unlimited Ai Written Grants</li>
                                                        <li>Unlimited Fundraising Events</li>
                                                        <li>Unlimited Ticketing Tiers</li>
                                                        <li>Unlimited Public Board of Director Sessions</li>
                                                        <li>Unlimited Program Management</li>
                                                        <li>Embedded Donation Widget</li>
                                                        <li>Unlimited Newsletters</li>
                                                        <li>Donor Wealth Profiles</li>
                                                        <li>White Labeling</li>
                                                        <li>Custmized Verbiage</li>
                                                        <li>Custom Domains</li>
                                                        <li>Custom Emails</li>
                                                    </ul>
                                                    </div>



                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                            </div>
                    </section>

                    

                    <section className="product-area pt-50 pb-70 fix">
                        <a id='why-us-section' className='anchor'/>
                        <div className="counter-area pt-60 pb-40 parallax">
                            <div className="container">
                                <div className="row wow fadeInUp animated"  data-delay=".1s">
                                    <div className="col-lg-6">
                                        <div className="section-title center-align mb-40 text-left">
                                            <h2 className="secondary-heading">
                                                The right choice at the right price.
                                            </h2>
                                            <p>
                                                Teaming up with NonprofitsHQ is choosing a dedicated partner committed to your organization's success. Our nonprofit management platform is designed for organizations of all sizes. Join us to amplify your impact.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="section-title center-align mb-6 text-left">
                                            <img src={chooseUsGraphic} alt='eventsGraphic'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    

                </main>
            <Footer/>
        </Fragment>
    )
};
export default PricingPage;