import React, {Fragment} from 'react';
import Header from '../../components/header'
import Footer from '../../components/footer'
import './style.css';
import impactGraphic from '../../images/bg/impact.249cccea581a9f644391.jpg';
import impactGraphic2 from '../../images/gallery/pascal-bernardon-HtM3uZl6UfI-unsplash.jpg';
import whiteSectionBackground from '../../images/bg/white-with-squares.png'
import pageTitleBackground from '../../images/titles/ImpactPageTitle.png';
import CallToAction from '../../components/CallToAction';

const ImpactPage =() => {

    document.title = "Impact Management | NonprofitsHQ"

    return(
        <Fragment>
            <Header/>
                <main>
                <section className="breadcrumb-area d-flex align-items-center parallax" style={{background: `url(${pageTitleBackground}) no-repeat`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-12">
                                <div className="breadcrumb-wrap text-left">
                                    <div className="breadcrumb-title mb-30">
                                        <h2>Impact</h2>
                                        <p style={{fontSize: '22px', color: '#fff', maxWidth: '800px'}}>Track your success with NonprofitsHQ.</p>
                                        <div className="breadcrumb-wrap">

                                    <nav aria-label="breadcrumb">

                                    </nav>
                                </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <div className="counter-area pt-60 pb-40 parallax">
                    <div className="container">
                        <div className="row wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-lg-6">
                                <div className="section-title center-align mb-40 text-left">
                                    <h2 className="secondary-heading">
                                        Optimize your community impact with NonprofitsHQ
                                    </h2>
                                    <p>
                                        Capture, measure, and showcase your nonprofit's impact effortlessly with our innovative community impact measuring and reporting tool. Gain deeper insights into your initiatives, track progress, and communicate your achievements effectively. Empower your organization to tell compelling stories of change and demonstrate tangible outcomes. Request a demo to see how your impact will shine with NonprofitsHQ.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="section-title center-align mb-6 text-left" style={{borderRadius: '5px', border: '10px solid #075272ff'}}>
                                    <img src={impactGraphic} alt='impactGraphic'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className='blue-section d-flex justify-content-center pb-50'>
                    <div className="container mt-5">
                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-md-12 mb-4 text-center">
                                <h2>Discover the power of your impact through:</h2>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                        <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Online Surveys</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Stakeholder Engagement</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Data Visualization</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Program Tracking</p>
                                    </div>
                                </div>
                            </div>                         

                        </div>
                    </div>
                </section>

                <section className='bootstrap-section pt-60 pb-40' style={{background: `url(${whiteSectionBackground}) no-repeat`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-md-5">
                                <div className="left-side">
                                    <div className="section-title center-align mb-6 text-left" style={{borderRadius: '5px', border: '10px solid #075272ff'}}>
                                        <img src={impactGraphic2} alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="right-side">
                                    <div className="section-title center-align mb-6 text-left" style={{background: 'rgba(255,255,255, .9)', padding: '5px', borderRadius: '15px'}}>
                                        <h2 className="secondary-heading">
                                            Impact tracking isn't just about numbers.
                                        </h2>
                                        <p>
                                        Impact tells a story about a nonprofit's operation: demonstrating accountability and impact.
                                            Accurately measure your impact to further drive support and ensure meaningful support to the communities you serve.
                                            NonprofitsHQ provides you with the tools to efficiently record your organization's impact.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <CallToAction />
            </main>
        <Footer/>
    </Fragment>
    )
};
export default ImpactPage;