import React, {Fragment} from 'react';
import Header from '../../components/header'
import Footer from '../../components/footer'
import './style.css';
import donateGraphic from '../../images/screenshots/AdminFundraisingCampaigns.png';
import donateGraphic2 from '../../images/screenshots/AdminDashboard.png';
import whiteSectionBackground from '../../images/bg/white-with-squares.png';
import pageTitleBackground from '../../images/titles/FundraisingPageTitle.png'
import CallToAction from '../../components/CallToAction';

const FundRaisingPage =() => {

    document.title = "Fundraising | NonprofitsHQ"
    return(
        <Fragment>
            <Header/>
                <main>
                <section className="breadcrumb-area d-flex align-items-center parallax" style={{background: `url(${pageTitleBackground}) no-repeat`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-12">
                                <div className="breadcrumb-wrap text-left">
                                    <div className="breadcrumb-title mb-30">
                                        <h2>Fundraising</h2>
                                        <p style={{fontSize: '22px', color: '#fff', maxWidth: '800px'}}>Powerful online and offline fundraising to help you acheive your goals.</p>
                                        <div className="breadcrumb-wrap">

                                    <nav aria-label="breadcrumb">

                                    </nav>
                                </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <div className="counter-area pt-60 pb-40 parallax">
                    <div className="container">
                        <div className="row wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-lg-6">
                                <div className="section-title center-align mb-40 text-left">
                                    <h2 className="secondary-heading">
                                        Raise more money with NonprofitsHQ
                                    </h2>
                                    <p>
                                        Unleash the power of your fundraising initiatives with NonprofitsHQ's advanced tools, meticulously designed to boost donations without sacrificing user-friendly simplicity. Powerful tools that are easy to use - that's the NonprofitsHQ difference.

                                        Recurring donations, unlimited campaigns, no-code donation pages and powerful insights/reccomendations are just the start. Request a demo today to learn how you can level up your fundraising efforts with NonprofitsHQ.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="section-title center-align mb-6 text-left">
                                    <img src={donateGraphic} alt=''/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className='blue-section d-flex justify-content-center pb-50'>
                    <div className="container mt-5">
                        <div className="row justify-content-center wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-md-12 mb-4 text-center">
                                <h2>Optimize your organization's success through:</h2>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                        <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Fundraising Campaigns </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Online Donations</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Donor Tracking</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4 wow fadeInUp animated"  data-delay=".1s">
                                <div className="card d-flex align-items-center">
                                    <div className="card-body text-center d-flex align-items-center card-100">
                                        <i className="fa fa-check mr-3 icon"></i>
                                        <p className="mb-0">Recurring Donations</p>
                                    </div>
                                </div>
                            </div>                         

                        </div>
                    </div>
                </section>

                <section className='bootstrap-section pt-60 pb-40' style={{background: `url(${whiteSectionBackground}) no-repeat`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row wow fadeInUp animated"  data-delay=".1s">
                            <div className="col-md-6">
                                <div className="left-side">
                                    <div className="section-title center-align mb-6 text-left">
                                        <img src={donateGraphic2} alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="right-side">
                                    <div className="section-title center-align mb-6 text-left" style={{background: 'rgba(255,255,255, 1)', padding: '5px', borderRadius: '15px'}}>
                                        <h2 className="secondary-heading">
                                            Fuel your organization's impact
                                        </h2>
                                        <p>
                                            Whether it's supporting a cause, advancing research, or empowering communities, fundraising is the livelyhood of impactful initiatives.
                                            NonprofitsHQ provides organizations a centralized platform that truly empowers fundraising initiatives.
                                            Let's continue to rally support and drive positive change together.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <CallToAction />

            </main>
        <Footer/>
    </Fragment>
    )
};
export default FundRaisingPage;